// inquiry.scss

@import '../../../../assets/css/foundation.scss';

.inquiry {
  &__inner {
    >.board_top {
      width: 100%;
      margin: 24px 0;
      padding: 16px 24px;
      background: $neutral-50;
      box-sizing: border-box;
      @include NanumSquare-body-14($font-color: $neutral-600);

      >span {
        color: $warning-600;
      }
    }

    >.board {
      width: 100%;
      padding: 0 !important;
      border-top: 1px solid $neutral-200;
      border-bottom: none !important;

      >.non_login {
        @include flex(flex, $display-flex-column-no, center, center);
        margin: 160px 0;

        >.img {
          width: 120px;
          height: 120px;
          background: url(../../../../assets/images/inquiry_login.png) no-repeat 50% 50%;
          background-size: contain;
          margin-bottom: 16px;
        }

        >.title {
          @include NanumSquare-body-16UB($font-color: $neutral-700, $align: center);
          margin-bottom: 4px;
        }

        >.description {
          @include NanumSquare-body-14($font-color: $neutral-500);
          margin-bottom: 16px;
        }

        >.button_box {
          @include flex(flex, $display-flex-row-no, center, center);

          >div {
            &:first-of-type {
              margin-right: 8px;
            }
          }
        }
      }

      >.no_list {

        >.img {
          background: url(../../../../assets/images/inquiry_nolist.png) no-repeat 50% 50% !important;
          background-size: contain;
        }
      }
    }
  }
}

.write {
  &__inner {
    // padding-bottom: 32px !important;
  }

  &__body {
    width: 1170px;
    @include flex(flex, $display-flex-column-no, flex-start, flex-start);
    gap: 16px;
    margin-bottom: 32px;

    &--top {
      width: 100%;
      @include flex(flex, $display-flex-row-no, center, center);
      gap: 8px;
    }

    &--editor {
      width: 100%;
    }

    &--bottom {
      width: 100%;
      @include flex(flex, $display-flex-column-no, center, center);
      margin-top: 16px;

      >.top {
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, space-between);

        >.title {
          @include NanumSquare-label-12UB($font-color: $neutral-700);

          >span {
            @include NanumSquare-label-12UB($font-color: $neutral-400);
            margin-left: 8px;
          }
        }

        >.button--upload {
          >.button {
            display: block;
            padding: 12px 16px 12px 38px;
            border: 1px solid $neutral-300;
            border-radius: 8px;
            background: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21ZM5 21L16 10L21 15M10 8.5C10 9.32843 9.32843 10 8.5 10C7.67157 10 7 9.32843 7 8.5C7 7.67157 7.67157 7 8.5 7C9.32843 7 10 7.67157 10 8.5Z' stroke='%231D2939' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 16px 50%;
            @include NanumSquare-label-12UB($font-color: $neutral-700);
            cursor: pointer;

            &:hover {
              background: $neutral-50 url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21ZM5 21L16 10L21 15M10 8.5C10 9.32843 9.32843 10 8.5 10C7.67157 10 7 9.32843 7 8.5C7 7.67157 7.67157 7 8.5 7C9.32843 7 10 7.67157 10 8.5Z' stroke='%231D2939' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 16px 50%;
            }

            &:focus {
              box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7;
            }
          }

          >.hide {
            display: none;
          }
        }
      }

      >.img--box {
        position: relative;

        >img {
          width: 264px;
          height: 133px;
          border-radius: 8px;
          overflow: hidden;
          object-fit: cover;
        }

        >.button--file--delete {
          position: absolute;
          top: -5px;
          right: -5px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: $black url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18M6 6L18 18' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
          cursor: pointer;
        }
      }
    }
  }

  &__bottom {
    @include flex(flex, $display-flex-row-no, center, center);
    gap: 8px;
  }
}

@media (max-width: 1402px) {
  .write {
    &__body {
      width: 100%;
    }

    &__bottom {
      margin-bottom: 106px;
    }
  }
}

@media (max-width: 760px) {
  .inquiry {
    &__inner {
      >.board_top {
        width: 100%;
        margin: 24px 0;
        padding: 16px 24px;
        background: $neutral-50;
        box-sizing: border-box;
        @include NanumSquare-body-14($font-color: $neutral-600);

        >span {
          color: $warning-600;
        }
      }

      >.board {
        width: 100%;
        padding: 0 !important;
        border-top: 1px solid $neutral-200;
        border-bottom: none !important;

        >.non_login {
          @include flex(flex, $display-flex-column-no, center, center);
          margin: 160px 0;

          >.img {
            width: 120px;
            height: 120px;
            background: url(../../../../assets/images/inquiry_login.png) no-repeat 50% 50%;
            background-size: contain;
            margin-bottom: 16px;
          }

          >.title {
            @include NanumSquare-body-16UB($font-color: $neutral-700, $align: center);
            margin-bottom: 4px;
            padding: 0 50px;
          }

          >.description {
            @include NanumSquare-body-14($font-color: $neutral-500);
            margin-bottom: 16px;
          }

          >.button_box {
            @include flex(flex, $display-flex-row-no, center, center);

            >div {
              &:first-of-type {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  .write {
    &__body {
      width: 100%;

      &--top {
        @include flex(flex, $display-flex-column-no, center, center);

        >.select-wrap {
          width: 100%;

          >.select {
            width: 100%;

            &.classification {
              width: 100%;
            }
          }

          >.select-ul {
            &.classification {
              width: 100%;
            }
          }
        }
      }
      .toastui-editor-popup {
        max-width: inherit;
        margin-left: inherit;
      }
    }

    &__bottom {
      margin-bottom: 64px;
    }
  }
}

@media (max-width: 600px) {
  .write {
    &__body {
      .toastui-editor-dropdown-toolbar {
        max-width: 100%;
        overflow-y: scroll;
        right: 0 !important;
      }
      .toastui-editor-popup {
        &.toastui-editor-popup-add-image {
          max-width: 100%;
          margin-left: inherit;
          top: 88px !important;
          left: 0 !important;
          .toastui-editor-file-select-button {
            width: inherit;
          }
        }
      }
    }
  }
}