// noticePage.scss

@import '../../../../assets/css/foundation.scss';

.notice,
.event,
.update,
.faq,
.inquiry,
.freeboard,
.screenshot,
.detail,
.write,
.studio,
.my-contents,
.mypage {
  width: 100%;
  min-height: calc(100vh - 217px);
  @include flex(flex, $display-flex-column-no, center, flex-start);
  padding-top: 48px;
  padding-bottom: 124px;

  &__inner {
    position: relative;
    width: $web-width;
    @include flex(flex, $display-flex-column-no, center, flex-start);
    padding-bottom: 36px;

    >.page_top {
      width: 100%;
      @include flex(flex, $display-flex-column-no, flex-start, flex-start);
      border-bottom: 1px solid $neutral-200;

      >.title {
        @include NanumSquare-title-22UB($font-color: $neutral-700);
        margin-bottom: 8px;
      }

      >.description {
        @include NanumSquare-body-12($font-color: $neutral-700);
        margin-bottom: 8px;
      }
    }

    >.board {
      width: 100%;
      // padding: 24px 16px;
      margin-bottom: 32px;

      .board__top {
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, space-between);
        margin-top: 32px;
        margin-bottom: 16px;

        >.right {
          @include flex(flex, $display-flex-row-no, center, center);

          >.select-wrap {
            margin-right: 8px;
          }

          >.input_wrapper {
            gap: 0;

            >.search_input {
              align-items: center;
              padding: 8px 14px;
            }
          }

          >svg {
            display: none;
            cursor: pointer;

            >path {
              stroke: $neutral-400;
            }
          }
        }
      }

      .board__inner {
        width: 100%;

        div,
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        li,
        th,
        td {
          word-break: break-all;
        }

        iframe {
          // width: 100%;
          // height: 40vh;
          border-radius: 0 !important;
          outline: none !important;
        }
      }

      .no_list {
        width: 100%;
        @include flex(flex, $display-flex-column-no, center, center);
        margin: 220px 0;

        >.img {
          width: 135px;
          height: 135px;
          background: url(../../../../assets/images/board_nolist.png) no-repeat 50% 50%;
          background-size: contain;
          margin-bottom: 16px;
        }

        >.title {
          @include NanumSquare-body-16UB($font-color: $neutral-700);
          margin-bottom: 4px;
        }

        >.description {
          @include NanumSquare-body-14($font-color: $neutral-500);
        }
      }

      &.border {
        border-bottom: 1px solid $neutral-200;
      }

      >.board__body {
        width: 100%;
        border-top: 1px solid #EAECF0;
        margin-bottom: 52px;
        @include flex(flex, $display-flex-column-no, center, center);

        >.loading {
          margin: 200px 0;
        }

      }



      >.board__bottom {
        width: 100%;
        position: relative;
        @include flex(flex, $display-flex-row-no, center, center);

        >.mobile_pagination {
          display: none;
        }

        >.normal_button {
          position: absolute;
          bottom: calc(50% - 20px);
          right: 0;
        }

        >.board__pagination {
          width: 100%;
          @include flex(flex, $display-flex-row-no, center, center);

          >li {
            width: 40px;
            height: 40px;
            margin-right: 8px;
            box-sizing: border-box;

            @include NanumSquare-body-14B($font-color: $neutral-500);

            >a {
              width: 100%;
              height: 100%;
              @include flex(flex, $display-flex-row-no, center, center);
            }

            &:first-of-type,
            &:nth-of-type(2),
            &:nth-last-of-type(2),
            &:last-of-type {
              width: 48px;
              height: 32px;
              text-indent: -9999px;
              overflow: hidden;
            }

            &:first-of-type,
            &:last-of-type {
              background: url(../../../../assets/images/chevrons-left.png) no-repeat 50% 50%;
              background-size: auto;

              &:hover {
                background: $neutral-50 url(../../../../assets/images/chevrons-left.png) no-repeat 50% 50%;
                background-size: auto;
              }

              &:last-of-type {
                margin-right: 0;
                transform: rotate(180deg);
              }
            }

            &:nth-of-type(2),
            &:nth-last-of-type(2) {
              background: url(../../../../assets/images/chevron-left.png) no-repeat 50% 50%;
              background-size: auto;

              &:hover {
                background: $neutral-50 url(../../../../assets/images/chevron-left.png) no-repeat 50% 50%;
                background-size: auto;
              }

              &:nth-last-of-type(2) {
                transform: rotate(180deg);
              }
            }

            &.selected {
              background: $neutral-50;
              border-radius: 8px;
              color: $neutral-600;
            }
          }

          >.previous,
          >.next {
            width: 48px;
            height: 32px;

            >a {
              background: $white;
              border: 1px solid $neutral-300;
              border-radius: 8px;
              text-indent: -9999px;
              overflow: hidden;
              background: url(../../../../assets/images/chevrons-left.png) no-repeat 50% 50%;
            }

            &.next {
              >a {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      >.board__buttons {
        width: 100%;
        position: relative;
        @include flex(flex, $display-flex-row-no, center, center);
        margin-top: 48px;
        padding-bottom: 40px;

        >.normal_button {
          .icon.leading {
            &::after {
              top: inherit;
              left: inherit;
              width: 20px;
              height: 20px;
              background: inherit;
              background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 15C10.3872 15 10.2917 14.9609 10.2135 14.8828L6.15105 10.9635C6.10764 10.9288 6.04797 10.8724 5.97201 10.7943C5.89606 10.7161 5.77561 10.574 5.61068 10.3678C5.44575 10.1617 5.29818 9.95009 5.16797 9.73307C5.03777 9.51606 4.92166 9.25347 4.81967 8.94531C4.71767 8.63715 4.66667 8.33767 4.66667 8.04688C4.66667 7.09201 4.94228 6.34549 5.49349 5.80729C6.04471 5.2691 6.80643 5 7.77865 5C8.04775 5 8.32227 5.04666 8.60222 5.13997C8.88217 5.23329 9.14258 5.35916 9.38347 5.51758C9.62435 5.676 9.8316 5.82465 10.0052 5.96354C10.1788 6.10243 10.3438 6.25 10.5 6.40625C10.6563 6.25 10.8212 6.10243 10.9948 5.96354C11.1684 5.82465 11.3757 5.676 11.6165 5.51758C11.8574 5.35916 12.1178 5.23329 12.3978 5.13997C12.6777 5.04666 12.9523 5 13.2214 5C14.1936 5 14.9553 5.2691 15.5065 5.80729C16.0577 6.34549 16.3333 7.09201 16.3333 8.04688C16.3333 9.00608 15.8364 9.98264 14.8425 10.9766L10.7865 14.8828C10.7083 14.9609 10.6129 15 10.5 15Z' fill='%2398A2B3'/%3E%3C/svg%3E");
            }

            &::before {
              display: none;
            }
          }

          &.on {
            .icon.leading {
              &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 15C10.3872 15 10.2917 14.9609 10.2135 14.8828L6.15105 10.9635C6.10764 10.9288 6.04797 10.8724 5.97201 10.7943C5.89606 10.7161 5.77561 10.574 5.61068 10.3678C5.44575 10.1617 5.29818 9.95009 5.16797 9.73307C5.03777 9.51606 4.92166 9.25347 4.81967 8.94531C4.71767 8.63715 4.66667 8.33767 4.66667 8.04688C4.66667 7.09201 4.94228 6.34549 5.49349 5.80729C6.04471 5.2691 6.80643 5 7.77865 5C8.04775 5 8.32227 5.04666 8.60222 5.13997C8.88217 5.23329 9.14258 5.35916 9.38347 5.51758C9.62435 5.676 9.8316 5.82465 10.0052 5.96354C10.1788 6.10243 10.3438 6.25 10.5 6.40625C10.6563 6.25 10.8212 6.10243 10.9948 5.96354C11.1684 5.82465 11.3757 5.676 11.6165 5.51758C11.8574 5.35916 12.1178 5.23329 12.3978 5.13997C12.6777 5.04666 12.9523 5 13.2214 5C14.1936 5 14.9553 5.2691 15.5065 5.80729C16.0577 6.34549 16.3333 7.09201 16.3333 8.04688C16.3333 9.00608 15.8364 9.98264 14.8425 10.9766L10.7865 14.8828C10.7083 14.9609 10.6129 15 10.5 15Z' fill='%23F04438'/%3E%3C/svg%3E%0A");
              }
            }
          }

          &:hover {
            .icon.leading {
              &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 15C10.3872 15 10.2917 14.9609 10.2135 14.8828L6.15105 10.9635C6.10764 10.9288 6.04797 10.8724 5.97201 10.7943C5.89606 10.7161 5.77561 10.574 5.61068 10.3678C5.44575 10.1617 5.29818 9.95009 5.16797 9.73307C5.03777 9.51606 4.92166 9.25347 4.81967 8.94531C4.71767 8.63715 4.66667 8.33767 4.66667 8.04688C4.66667 7.09201 4.94228 6.34549 5.49349 5.80729C6.04471 5.2691 6.80643 5 7.77865 5C8.04775 5 8.32227 5.04666 8.60222 5.13997C8.88217 5.23329 9.14258 5.35916 9.38347 5.51758C9.62435 5.676 9.8316 5.82465 10.0052 5.96354C10.1788 6.10243 10.3438 6.25 10.5 6.40625C10.6563 6.25 10.8212 6.10243 10.9948 5.96354C11.1684 5.82465 11.3757 5.676 11.6165 5.51758C11.8574 5.35916 12.1178 5.23329 12.3978 5.13997C12.6777 5.04666 12.9523 5 13.2214 5C14.1936 5 14.9553 5.2691 15.5065 5.80729C16.0577 6.34549 16.3333 7.09201 16.3333 8.04688C16.3333 9.00608 15.8364 9.98264 14.8425 10.9766L10.7865 14.8828C10.7083 14.9609 10.6129 15 10.5 15Z' fill='%231D2939'/%3E%3C/svg%3E%0A");
              }
            }
          }

          &.report {
            position: absolute;
            bottom: calc(50% - 0px);
            right: 0;

            .icon.leading {
              &::after {
                top: 2px;
                left: 2px;
                width: 12.5px;
                height: 14.17px;
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='%2398A2B3' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                background-position: 50% 50%;
                background-size: contain;
                background-repeat: no-repeat;
              }
            }

            &:hover {
              .icon.leading {
                &::after {
                  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='%231D2939' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22' stroke='%231D2939' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }
              }
            }
          }
        }
      }
    }

    // inquiry 임시 페이지 -> 추후 업데이트 후 삭제
    >.temporary {
      width: 100%;
      margin-top: 24px;
      padding: 165px 0;
      background: $neutral-50;
      @include flex(flex, $display-flex-column-no, center, center);

      >.center {
        @include flex(flex, $display-flex-column-no, center, center);

        >.image {
          width: 120px;
          height: 120px;
          background: url(../../../../assets/images/inquiry_nolist.png) no-repeat 50% 50%;
          background-size: contain;
        }

        >.description {
          margin: 24px 0;
          @include NanumSquare-body-14($font-color: $neutral-700, $align: center);

          >.strong {
            color: $warning-600;
          }

          >a {
            color: $primary-600;
          }
        }
      }
    }

    &__bottom {
      width: 100%;
      // padding-top: 36px;
      @include flex(flex, $display-flex-row-no, center, flex-end);

      .right {
        @include flex(flex, $display-flex-row-no, center, center);

        .normal_button {
          &:last-of-type {
            margin-left: 8px;
          }
        }
      }

      .secondary_gray {
        position: absolute;
        left: 0;
      }
    }
  }

  &.event,
  &.screenshot {
    >.event__inner,
    > .screenshot__inner {
      >.board {
        padding: 0;

        >.board__body {
          border-top: none;
          @include flex(flex, $display-flex-row-wrap, center, flex-start);
          gap: 24px;
        }
      }
    }
  }

  &.freeboard {
    >.freeboard__inner {
      >.board {
        >.board__body {
          >.board_list {
            >.category {
              display: none;
            }
          }

          >.board_list.fixed {
            >.category {
              display: block;
            }

            // > .title_box {
            //   position: absolute;
            //   bottom: 12px;
            // }
          }
        }
      }
    }
  }

  &.my_post {
    .detail__inner__bottom {
      justify-content: space-between;
    }
  }

  &.studio,
  &.my-contents {
    position: relative;
    min-height: calc(100vh - 197px);

    &.studio {
      padding-top: 0;
    }
  }

  &.notice,
  &.update,
  &.event {
    .detail__inner {
      &__bottom {
        padding-top: 0;
        @include flex(flex, $display-flex-row-no, center, center);
      }
    }
  }

  &.write {
    &__inner {
      padding-bottom: 32px;
    }
  }

  &.mypage {
    padding-top: 139px;
    min-height: calc(100vh - 153px);
    width: calc(100% - 232px);
    margin-left: 232px;

    >.mypage__inner {
      >.box__profile {
        width: 100%;
        margin-bottom: 48px;

        >tbody {
          width: 100%;

          >tr {
            width: 100%;
            border-bottom: 1px solid $neutral-200;

            >th {
              background: $neutral-50;
              padding: 16px;
              @include NanumSquare-label-14($font-color: $neutral-500, $align : center);
            }

            >td {
              @include flex(flex, $display-flex-row-no, center, flex-start);
              padding: 16px;
              box-sizing: border-box;
              gap: 16px;

              >.profile {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                object-fit: cover;
              }

              >.input_wrapper {
                min-width: 300px;
                width: auto;

                >input[disabled] {
                  background: $neutral-200;
                  border: 1px solid $neutral-300;
                  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                  color: $neutral-400;
                  pointer-events: none;
                }
              }

              >.button--upload {
                >.button {
                  padding: 10px 20px;
                  border-radius: 10px;
                  @include NanumSquare-label-16UB($font-color: $neutral-700);
                  background: white;
                  border: 1px solid $neutral-300;
                  cursor: pointer;
                  color: $neutral-700;

                  &:hover {
                    background: $neutral-50;
                    border: 1px solid $neutral-400;

                    >p {
                      color: $neutral-800;
                    }
                  }
                }

                >.hide {
                  display: none;
                }
              }

              > .box_platform {
                @include flex(flex, $display-flex-row-no, center, flex-start);
              }
            }

            &:nth-of-type(3) {
              td {
                @include flex(flex, $display-flex-column-no, flex-start, flex-start);
                > .box_platform {
                  @include flex(flex, $display-flex-row-no, center, center);
                  gap: 8px;
                  > .icon {
                    width: 24px;
                    height: 24px;
                  }
                  > p {
                    @include NanumSquare-label-12B($font-color: $info-600);
                  }
                  &.google {
                    > .icon {
                      background: url(../../../../assets/images/zemiverse/google.png) no-repeat 50% 50%;
                      background-size: contain;
                    }
                    > p {
                      @include NanumSquare-label-12B($font-color: $info-600);
                    }
                  }
                  &.apple {
                    > .icon {
                      background: url(../../../../assets/images/zemiverse/apple.png) no-repeat 50% 50%;
                      background-size: contain;
                    }
                    > p {
                      @include NanumSquare-label-12B($font-color: $info-600);
                    }
                  }
                  &.facebook {
                    > .icon {
                      background: url(../../../../assets/images/zemiverse/facebook.png) no-repeat 50% 50%;
                      background-size: contain;
                    }
                    > p {
                      @include NanumSquare-label-12B($font-color: $info-600);
                    }
                  }
                }
              }
            }
          }
        }
      }

      >.box__buttons {
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, center);
        gap: 8px;
      }
    }
  }
}

@media (max-width : 1402px) {

  .notice,
  .event,
  .update,
  .faq,
  .inquiry,
  .freeboard,
  .screenshot,
  .detail,
  .write,
  .studio,
  .my-contents,
  .mypage {
    width: 100%;
    min-height: calc(100vh - 258.19px);
    @include flex(flex, $display-flex-column-no, center, flex-start);
    padding: 30px 24px 0 24px;

    &.studio {
      padding: 0;

      &__inner {
        width: $tablet-width;
      }
    }

    &__inner {
      width: 100%;
      @include flex(flex, $display-flex-column-no, center, flex-start);
      padding-bottom: 32px;
      overflow-x: hidden;

      >.page_top {
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, space-between);
        border-bottom: 1px solid $neutral-200;
        // margin-bottom: 32px;

        >.title {
          @include NanumSquare-title-22UB;
          margin-bottom: 8px;
        }

        >.description {
          display: none;
        }
      }

      >.board {
        width: 100%;
        // border-bottom: 1px solid $neutral-200;
        padding: 0; //바뀜
        margin-bottom: 32px;

        >.no_list {
          width: 100%;
          @include flex(flex, $display-flex-column-no, center, center);
          margin: 110px 0;

          >.img {
            width: 135px;
            height: 135px;
            background: url(../../../../assets/images/board_nolist.png) no-repeat 50% 50%;
            background-size: contain;
            margin-bottom: 16px;
          }

          >.title {
            @include NanumSquare-body-16UB($font-color: $neutral-700);
            margin-bottom: 4px;
          }

          >.description {
            @include NanumSquare-body-14($font-color: $neutral-500);
          }
        }

        >.board__top {
          width: 100%;
          @include flex(flex, $display-flex-row-no, center, space-between);
          margin-top: 16px; //바뀜
          margin-bottom: 8px; //바뀜

          >.right {

            >.select-wrap,
            >.input_wrapper {
              display: none;
            }

            >svg {
              display: block !important;
              width: 40px;
              height: 40px;
              padding: 8px;
              box-sizing: border-box;
            }
          }
        }

        >.board__body {
          width: 100%;
          border-top: 1px solid #EAECF0;
          margin-bottom: 36px;
          @include flex(flex, $display-flex-column-no, center, center);
        }

        >.board__bottom {
          width: 100%;
          position: relative;
          @include flex(flex, $display-flex-row-no, center, center);

          >.mobile_pagination {
            display: block;
            @include flex(flex, $display-flex-row-no, center, center);

            >.normal_button {
              width: 36px;
              height: 36px;
              background: url(../../../../assets/images/arrow_left.png) no-repeat 50% 50%;
              background-size: 20px;
              box-sizing: border-box;

              >p {
                text-indent: -9999px;
                overflow: hidden;
              }

              &:last-of-type {
                transform: rotate(180deg);
              }
            }

            >.page {
              margin: 0 75px;
              @include NanumSquare-body-14;
            }
          }

          >.normal_button {
            position: absolute;
            bottom: calc(50% - 20px);
            right: 0;
          }

          >.board__pagination {
            display: none;
          }
        }
      }

    }

    &.detail {
      .detail__inner {
        >.board {
          padding: 24px 16px;
        }
      }
    }

    &.event {
      &__inner {
        >.board {
          padding: 0;

          >.board__body {
            border-top: none;
            @include flex(flex, $display-flex-row-wrap, center, space-between);
          }
        }
      }
    }

    &.studio,
    &.my-contents {
      min-height: calc(100vh - 200px);
    }

    &.mypage {
      padding-top: 86px;
      min-height: calc(100vh - 202.19px);
      width: 100%;
      margin-left: 0;

      >.mypage__inner {
        >.box__profile {
          >tbody {
            >tr {
              >th {
                // min-width: inherit;
                max-width: 220px;
              }
            }
          }
        }
      }
    }
  }

}

@media (max-width: 760px) {

  .notice,
  .event,
  .update,
  .faq,
  .inquiry,
  .freeboard,
  .screenshot,
  .detail,
  .write,
  .studio,
  .my-contents,
  .mypage {
    min-height: calc(100vh - 258.19px);
    padding: 24px 15px 0 15px;

    &.studio {
      padding: 0;

      &__inner {
        width: $mobile-width;
      }
    }

    &__inner {
      width: 100%;
      overflow-x: hidden;

      // inquiry 임시 페이지 -> 추후 업데이트 후 삭제
      >.temporary {
        margin-top: 0;
      }

      .board {
        > .board__bottom {
          width: 100%;
          position: relative;
          @include flex(flex, $display-flex-column-no, center, center);

          >.mobile_pagination {
            display: block;
            @include flex(flex, $display-flex-row-no, center, center);
            margin-bottom: 28px;

            >.normal_button {
              width: 36px;
              height: 36px;
              background: url(../../../../assets/images/arrow_left.png) no-repeat 50% 50%;
              background-size: 20px;
              box-sizing: border-box;

              >p {
                text-indent: -9999px;
                overflow: hidden;
              }

              &:last-of-type {
                transform: rotate(180deg);
              }
            }

            >.page {
              margin: 0 58px;
              @include NanumSquare-body-14;
            }
          }

          >.normal_button {
            width: 100%;
            position: inherit;
            bottom: inherit;
            right: inherit;
          }
        }
      }
    }

    &.detail {
      .detail__inner {
        width: 100%;

        >.board_list,
        >.comment,
        >.detail__inner__bottom {
          padding: 0 15px;

          &.board_list {
            padding: 16px 0 !important;
          }
        }

        >.board {
          padding: 24px 15px;

          img {
            width: 100%
          }
        }

      }
    }

    &.event {
      &__inner {
        >.board {
          >.board__body {
            @include flex(flex, $display-flex-column-no, center, center);
            margin-bottom: 28px;
          }
        }
      }
    }

    &.freeboard {
      &__inner {
        >.board {
          >.board__body {
            >.board_list.fixed {
              >.title_box {
                position: absolute;
                bottom: 12px;
              }
            }
          }
        }
      }
    }

    &.mypage {
      padding-top: 86px;
      min-height: calc(100vh - 202.19px);

      >.mypage__inner {
        >.box__profile {
          >tbody {
            >tr {
              >th {
                min-width: 120px;
                padding: 0;
              }

              >td {
                @include flex(flex, $display-flex-column-no, flex-start, center);

                >.input_wrapper {
                  width: 100%;
                  min-width: inherit;
                }
                > .button--upload {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

}