// faq.scss

@import '../../../../assets/css/foundation.scss';

.faq {
  width: 100%;
  &__inner {
    width: $web-width;
    > .board {
      width: 100%;
      margin-top: 32px;
      padding: 0 !important;
      box-sizing: border-box;
      border-bottom: none !important;
      border-top: 1px solid $neutral-200;
      >.board__list {
        width: 100%;
        cursor: pointer;
        > .title {
          position: relative;
          padding: 14px 0;
          border-bottom: 1px solid $neutral-200;
          @include NanumSquare-title-16UB($font-color: $neutral-700);
          &.on {
            color: $primary-700;
          }
          &::after {
            position: absolute;
            top: calc(50% - 10px);
            right: 0;
            content: "";
            width: 20px;
            height: 20px;
            background: url(../../../../assets/images/chevron-down.png) no-repeat 100% 50%;
            background-size: contain;
            @include transition(transform, .5s);
            transform: rotate(0);
          }
        }
        > .description {
          height: 0;
          overflow: hidden;
          padding: 0;
          background: $neutral-25;
          transition: all 0s linear .5s;
          
          @include NanumSquare-body-14($font-color: $neutral-600);
          @include transition(height, .5s);
          &::-webkit-scrollbar {
            display: block;
            width: 16px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $neutral-300;
            border-radius: 27px;
            background-clip: padding-box;
            border: 4px solid transparent;
          }
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
        }
        &.on {
          > .title {
            &::after {
              transform: rotate(180deg);
            }
          }
          > .description {
            height: auto;
            max-height: 184px;
            overflow-y: scroll;
            border-bottom: 1px solid $neutral-200;
            padding: 24px 16px;
            opacity: 1;
          }
        }
      }
    }
  }
}

@media (max-width: 1402px) {
  .faq {
    width: 100%;
    &__inner {
      width: $tablet-width;
      > .board {
        width: 100%;
        margin-top: 32px;
        padding: 0 !important;
        box-sizing: border-box;
        border-bottom: none !important;
        border-top: 1px solid $neutral-200;
        >.board__list {
          width: 100%;
          cursor: pointer;
          > .title {
            position: relative;
            padding: 14px 0;
            border-bottom: 1px solid $neutral-200;
            @include NanumSquare-title-16UB($font-color: $neutral-700);
            &.on {
              color: $primary-700;
            }
          }
          > .description {
            @include NanumSquare-body-14($font-color: $neutral-600);
            @include transition(height, .5s);
          }
          &.on {
            > .title {
              &::after {
                // transform: rotate(180deg);
              }
            }
            
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .faq {
    width: 100%;
    &__inner {
      width: $mobile-width;
    }
  }
}