// download.scss
@import "../../../../assets/css/foundation.scss";

.container {
  width: 100%;

  &.download {
    width: 100%;
    @include flex(flex, $display-flex-column-no, center, flex-start);

    > div {
      width: 100%;
      @include flex(flex, $display-flex-column-no, center, center);

      &.content1 {
        width: 100%;
        padding: 64px 0;
        @include flex(flex, $display-flex-column-no, center, center);

        > .content1__inner {
          width: 1170px;

          > .title {
            @include NanumSquare-display-57UB($font-color: $neutral-900, $align: center);
            margin-bottom: 16px;
          }

          > .description {
            @include NanumSquare-headline-24($font-color: $neutral-900, $align: center);
            margin-bottom: 40px;
          }

          > .boxes {
            width: 100%;
            @include flex(flex, $display-flex-row-no, center, center);
            gap: 8px;

            > div {
              width: 135px;
              height: 40px;
              border-radius: 5px;
              cursor: pointer;

              &.google {
                background: url(../../../../assets/images/download/download_google.png) no-repeat 50% 50%;
                background-size: contain;
              }

              &.apple {
                background: url(../../../../assets/images/download/download_apple.png) no-repeat 50% 50%;
                background-size: contain;
              }

              &.window {
                background: url(../../../../assets/images/download/download_window.png) no-repeat 50% 50%;
                background-size: contain;
              }
            }
          }
        }
      }

      &.content2 {
        width: 100%;
        height: 1080px;

        > .swiper {
          position: relative;
          width: 100%;
          height: 100%;

          > .swiper-wrapper {
            > .swiper-slide {
              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6) 100%);
              }

              > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              > .player {
                width: 100% !important;
                height: 100% !important;

                > div {
                  position: relative;
                  padding-bottom: 56.25%;
                  height: 0 !important;
                  overflow: hidden;
                  max-width: 100%;

                  > iframe {
                    position: absolute;
                    top: -10%;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              // &:nth-of-type(2) {
              //   background: url(../../../../assets/images/download/content2_img2.png) no-repeat 50% 50%;
              //   background-size: cover;
              // }
              // &:nth-of-type(3) {
              //   background: url(../../../../assets/images/download/content2_img3.png) no-repeat 50% 50%;
              //   background-size: cover;
              // }
              // &:nth-of-type(4) {
              //   background: url(../../../../assets/images/download/content2_img4.png) no-repeat 50% 50%;
              //   background-size: cover;
              // }
              // &:nth-of-type(5) {
              //   background: url(../../../../assets/images/download/content2_img5.png) no-repeat 50% 50%;
              //   background-size: cover;
              // }
            }
          }

          > .swiper-button-prev,
          > .swiper-button-next {
            z-index: 40;
            position: absolute;
            top: calc(50% - 20px);
            left: 32px;
            width: 40px;
            height: 40px;
            background: url(../../../../assets/images/download/content2_nav_button.png) no-repeat 50% 50%;
            background-size: contain;
            cursor: pointer;

            &.swiper-button-next {
              left: inherit;
              right: 32px;
              transform: rotate(180deg);
            }

            &::after {
              display: none;
            }
          }

          > .swiper-pagination {
            z-index: 30;
            position: absolute;
            bottom: 32px;
            width: 100%;
            height: 80px;
            @include flex(flex, $display-flex-row-no, center, center);
            gap: 16px;

            > .swiper-pagination-bullet {
              width: 128px;
              height: 80px;
              border-radius: 12px;
              cursor: pointer;

              &:nth-of-type(1) {
                background: url(../../../../assets/images/download/content2_bullet1.png) no-repeat 50% 50%;
                background-size: cover;
              }

              &:nth-of-type(2) {
                background: url(../../../../assets/images/download/content6_bullet6.png) no-repeat 50% 50%;
                background-size: cover;
              }

              &:nth-of-type(3) {
                background: url(../../../../assets/images/download/content2_bullet2.png) no-repeat 50% 50%;
                background-size: cover;
              }

              &:nth-of-type(4) {
                background: url(../../../../assets/images/download/content2_bullet3.png) no-repeat 50% 50%;
                background-size: cover;
              }

              &:nth-of-type(5) {
                background: url(../../../../assets/images/download/content2_bullet4.png) no-repeat 50% 50%;
                background-size: cover;
              }

              &:nth-of-type(6) {
                background: url(../../../../assets/images/download/content2_bullet5.png) no-repeat 50% 50%;
                background-size: cover;
              }
            }
          }
        }
      }

      &.content3 {
        position: relative;
        width: 100%;
        height: 991px;
        @include flex(flex, $display-flex-column-no, center, center);
        overflow: hidden;

        > .content3__inner__bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;

          > div {
            position: absolute;
          }

          > .img1 {
            top: 181px;
            left: 59px;
            width: 100px;
            height: 100px;
            background: url(../../../../assets/images/zemiverse_2306/content6_img3.png) no-repeat 50% 50%;
            background-size: contain;
          }

          > .img2 {
            top: 61px;
            left: 255px;
            width: 38px;
            height: 38px;
            background: url(../../../../assets/images/zemiverse_2306/content6_img1.png) no-repeat 50% 50%;
            background-size: contain;
            transform: rotateY(180deg);
          }

          > .img3 {
            top: 281px;
            left: 155px;
            width: 79px;
            height: 74px;
            background: url(../../../../assets/images/zemiverse_2306/content6_img10.png) no-repeat 50% 50%;
            background-size: contain;
            transform: rotateY(180deg);
          }

          > .img4 {
            bottom: 237px;
            left: 193px;
            width: 112px;
            height: 101px;
            background: url(../../../../assets/images/zemiverse_2306/content6_img8.png) no-repeat 50% 50%;
            background-size: contain;
            transform: rotateY(180deg);
          }

          > .img5 {
            top: 90px;
            left: 406px;
            width: 104px;
            height: 99px;
            background: url(../../../../assets/images/zemiverse_2306/content6_img7.png) no-repeat 50% 50%;
            background-size: contain;
            transform: rotateY(180deg);
          }

          > .img6 {
            top: 114px;
            right: 736px;
            width: 120px;
            height: 120px;
            background: url(../../../../assets/images/zemiverse_2306/content6_img5.png) no-repeat 50% 50%;
            background-size: contain;
            transform: rotateY(180deg);
          }

          > .img7 {
            bottom: 265px;
            right: 294px;
            width: 100px;
            height: 100px;
            background: url(../../../../assets/images/zemiverse_2306/content6_img3.png) no-repeat 50% 50%;
            background-size: contain;
            transform: rotateY(180deg);
          }

          > .img8 {
            top: 31px;
            right: 199px;
            width: 140px;
            height: 80px;
            background: url(../../../../assets/images/zemiverse_2306/content6_img2.png) no-repeat 50% 50%;
            background-size: contain;
            transform: rotateY(180deg);
          }

          > .img9 {
            bottom: 463px;
            right: 148px;
            width: 38px;
            height: 38px;
            background: url(../../../../assets/images/zemiverse_2306/content6_img11.png) no-repeat 50% 50%;
            background-size: contain;
            transform: rotateY(180deg);
          }
        }

        > .content3__inner {
          position: absolute;
          top: 273px;
          width: 1170px;
          height: 100%;

          > .top {
            position: relative;
            @include flex(flex, $display-flex-row-no, center, space-between);
            gap: 20px;

            > .left {
              max-width: 730px;
              @include flex(flex, $display-flex-column-no, flex-start, center);
              margin-bottom: 90px;

              > .title {
                @include NanumSquare-display-48UB($font-color: $neutral-900, $align: left);
                margin-bottom: 24px;
              }

              > .description {
                @include NanumSquare-body-22B($font-color: $neutral-900, $align: left);
              }
            }

            > .right {
              position: absolute;
              top: -200px;
              right: -150px;
              width: 600px;
              height: 637px;
              background: url(../../../../assets/images/download/content3_main.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }

          > .bottom {
            width: 100%;
            @include flex(flex, $display-flex-row-no, center, space-between);
            gap: 16px;

            > .box {
              flex: 1;
              height: 112px;
              border-radius: 16px;
              @include flex(flex, $display-flex-column-no, flex-start, center);
              background: $white;
              box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);
              padding: 24px 30px;

              p {
                width: 100%;

                &.title {
                  margin-bottom: 12px;
                  @include NanumSquare-title-18UB($font-color: $primary-700, $align: left);
                  white-space: nowrap;
                }

                &.description {
                  @include NanumSquare-label-18UB($font-color: $neutral-600, $align: left);
                }
              }

              > .logo {
                width: 97px;
                height: 28px;
                background: url(../../../../assets/images/download/nextor_logo.png) no-repeat 50% 50%;
                background-size: contain;
                cursor: pointer;
              }

              > .box__inner {
                width: 100%;
                @include flex(flex, $display-flex-row-no, center, flex-start);
                gap: 16px;

                > .icon {
                  width: 28px;
                  height: 28px;
                  cursor: pointer;

                  &.apple {
                    background: url(../../../../assets/images/download/social_apple.png) no-repeat 50% 50%;
                    background-size: contain;
                  }

                  &.google {
                    background: url(../../../../assets/images/download/social_google.png) no-repeat 50% 50%;
                    background-size: contain;
                  }

                  &.window {
                    background: url(../../../../assets/images/download/social_window.png) no-repeat 50% 50%;
                    background-size: contain;
                  }
                }
              }

              &:nth-of-type(1),
              &:nth-of-type(2) {
                > .description {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      &.content4 {
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) -62.85%, rgba(65, 31, 143, 0.85) 100%),
          url(../../../../assets/images/download/content4_bg.png) no-repeat 50% 50%;
        background-size: cover;
        padding: 220px 0;

        > .content4__inner {
          width: 100%;
          @include flex(flex, $display-flex-column-no, center, center);
          gap: 64px;

          > .box {
            width: 689px;
            background: rgba(22, 12, 41, 0.5);
            border-radius: 20px;
            padding: 36px;
            @include flex(flex, $display-flex-row-no, center, space-between);
            gap: 36px;
            transform: translateX(-200px);

            > .box__inner {
              @include flex(flex, $display-flex-column-no, flex-start, center);
              gap: 12px;

              > .title {
                @include NanumSquare-headline-28UB($font-color: $primary-500, $align: left);
              }

              > .description {
                @include NanumSquare-body-18($font-color: $white, $align: left);
                font-weight: 400;
              }
            }

            &:nth-of-type(1) {
              > .image {
                width: 175px;
                height: 144px;
                background: url(../../../../assets/images/download/content4_img1.png) no-repeat 50% 50%;
                background-size: contain;
              }
            }

            &:nth-of-type(2) {
              > .image {
                width: 200px;
                height: 164px;
                background: url(../../../../assets/images/download/content4_img2.png) no-repeat 50% 50%;
                background-size: contain;
              }
            }

            &:nth-of-type(3) {
              > .image {
                width: 166px;
                height: 140px;
                background: url(../../../../assets/images/download/content4_img3.png) no-repeat 50% 50%;
                background-size: contain;
              }
            }

            &:nth-of-type(4) {
              > .image {
                width: 185px;
                height: 164px;
                background: url(../../../../assets/images/download/content4_img4.png) no-repeat 50% 50%;
                background-size: contain;
              }
            }

            &:nth-of-type(2n) {
              transform: translateX(200px);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 2150px) {
  .container {
    &.download {
      > div {
        &.content2 {
          height: 900px;
        }
      }
    }
  }
}

@media (max-width: 1900px) {
  .container {
    &.download {
      > div {
        &.content2 {
          height: 800px;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .container {
    &.download {
      > div {
        &.content2 {
          height: 700px;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .container {
    &.download {
      > div {
        &.content2 {
          height: 600px;
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .container {
    &.download {
      > div {
        &.content1 {
          > .content1__inner {
            width: 720px;
          }
        }

        &.content2 {
          height: 432px;

          > .swiper {
            > .swiper-wrapper {
              > .swiper-slide {
                &:nth-of-type(1) {
                  > .player {
                    > div {
                      iframe {
                        top: inherit;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &.content3 {
          height: 1581px;

          > .content3__inner__bg {
            > .img1,
            > .img6,
            > .img9 {
              display: none;
            }

            > .img2 {
              top: 31px;
              left: initial;
              right: 54px;
              transform: rotateY(0);
            }

            > .img3 {
              top: 189px;
              left: initial;
              right: 190px;
              transform: rotateY(0);
            }

            > .img4 {
              bottom: initial;
              top: 807px;
              left: initial;
              right: -20px;
              transform: rotateY(0);
            }

            > .img5 {
              top: 60px;
              left: 359px;
              transform: rotateY(0);
            }

            > .img7 {
              bottom: initial;
              top: 151px;
              right: -30px;
            }

            > .img8 {
              top: 31px;
              right: initial;
              left: -50px;
              transform: rotateY(0);
            }
          }

          > .content3__inner {
            top: 0;
            width: 100%;
            padding: 220px 32px 0 32px;

            > .top {
              > .left {
                max-width: inherit;
                margin-bottom: 53px;

                > .title {
                  @include NanumSquare-headline-36($font-color: $neutral-900, $align: left);
                }

                > .description {
                  width: 85%;
                  @include NanumSquare-body-18($font-color: $neutral-400, $align: left);
                }
              }

              > .right {
                top: initial;
                bottom: -1015px;
                right: -32px;
                width: calc(100% + 64px);
                height: 756px;
              }
            }

            > .bottom {
              @include flex(flex, $display-flex-row-wrap, center, space-between);

              > .box {
                min-width: 200px;
              }
            }
          }
        }

        &.content4 {
          padding: 80px 0;

          > .content4__inner {
            gap: 24px;
            padding: 0 24px;

            > .box {
              width: 100%;
              transform: translateX(0);

              &:nth-of-type(2n) {
                transform: translateX(0);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .container {
    &.download {
      > div {
        &.content2 {
          height: 350px;
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .container {
    &.download {
      > div {
        &.content1 {
          padding: 48px 0 40px;

          > .content1__inner {
            width: 330px;

            > .title {
              @include NanumSquare-headline-32UB($font-color: $neutral-900, $align: center);
            }

            > .description {
              @include NanumSquare-body-18($font-color: $neutral-900, $align: center);
            }

            > .boxes {
              @include flex(flex, $display-flex-column-no, center, center);
            }
          }
        }

        &.content2 {
          height: 297px;

          > .swiper {
            > .swiper-wrapper {
              width: 100%;
              height: 202px;
            }

            > .swiper-button-prev,
            > .swiper-button-next {
              top: calc(50% - 20px);
              left: 15px;

              &.swiper-button-next {
                right: 15px;
              }
            }

            > .swiper-pagination {
              height: 95px;
              bottom: 0;
              background: $primary-700;
              padding: 15px;
              overflow-x: scroll;
              gap: 8px;

              > .swiper-pagination-bullet {
                width: 104px;
                height: 65px;
                border-radius: 8px;
              }
            }
          }
        }

        &.content3 {
          height: 1287px;

          > .content3__inner__bg {
            overflow: hidden;

            > .img1,
            > .img6,
            > .img9,
            > .img7 {
              display: none;
            }

            > .img2 {
              top: 31px;
              left: initial;
              right: -11px;
              transform: rotateY(0);
            }

            > .img3 {
              top: 182px;
              left: initial;
              right: -33px;
              transform: rotateY(0);
            }

            > .img4 {
              bottom: initial;
              top: 870px;
              left: initial;
              right: -20px;
              transform: rotateY(0);
            }

            > .img5 {
              top: 78px;
              left: 190px;
              transform: rotateY(0);
            }

            > .img8 {
              top: 51px;
              right: initial;
              left: -35px;
              transform: rotateY(0);
            }
          }

          > .content3__inner {
            top: 0;
            width: 100%;
            padding: 220px 15px 0 15px;

            > .top {
              > .left {
                max-width: inherit;
                margin-bottom: 40px;

                > .title {
                  @include NanumSquare-headline-28UB($font-color: $neutral-900, $align: left);
                }

                > .description {
                  width: 100%;
                  @include NanumSquare-body-16UB($font-color: $neutral-400, $align: left);
                }
              }

              > .right {
                top: initial;
                bottom: -730px;
                right: -32px;
                width: calc(100% + 64px);
                height: 354px;
              }
            }

            > .bottom {
              @include flex(flex, $display-flex-row-wrap, center, space-between);

              > .box {
                min-width: calc(50% - 8px);
              }
            }
          }
        }

        &.content4 {
          padding: 56px 0;

          > .content4__inner {
            gap: 24px;
            padding: 0 24px;

            > .box {
              width: 100%;
              transform: translateX(0);
              @include flex(flex, $display-flex-column-no, center, center);

              > .box__inner {
                > .title {
                  width: 100%;
                  @include NanumSquare-title-22UB($font-color: $primary-400, $align: center);
                }

                > .description {
                  width: 100%;
                  @include NanumSquare-body-16UB($font-color: $white, $align: center);
                  font-weight: 400;
                }
              }

              &:nth-of-type(2n) {
                transform: translateX(0);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .container {
    &.download {
      > div {
        &.content2 {
          height: 278px;
        }
      }
    }
  }
}
