@import '../../../assets/css/foundation.scss';
.App {
  width: 100%;
  @include flex(flex, $display-flex-column-no, center, flex-start);
  .main_body {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 64px);
    @include flex(flex, $display-flex-row-no, flex-start, space-between);
    margin-top: 64px;
    overflow-y: scroll;
  }
  &.main {
    > .main_body {
      margin-top: 0;
    }
  }
}

@media (max-width: 1450px) {
  .App {
    .main_body {
      min-height: calc(100vh - 56px);
      margin-top: 56px !important;
    }
  }
}

