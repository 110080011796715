@import '../../../assets/css/foundation.scss';


.content {
  &.not_found {
    @include flex(flex, $display-flex-column-no, center, center);
    width: 100%; 
    margin: 0;
    height: calc(100vh - 153px);
    >.not_found--image {
      @include image-style(306px, 254px, url(../../../assets/images/not_found_page_images.png) no-repeat 50% 50%, contain);
      margin-bottom: 32px;
    }
    >.not_found__title {
      @include NanumSquare-headline-28UB($font-color: $neutral-700, $align: center);
      margin-bottom: 20px;
    }
    >.not_found__description {
      @include NanumSquare-body-16UB($font-color:$neutral-500, $align: center);
      margin-bottom: 32px;
    }
    >.not_found__content {
      @include flex(flex, $display-flex-row-no, center, center);
      gap: 10px;
    }
  }
}

@media (max-width: 1170px) {
  .content {
    &.not_found {
      height: calc(100vh - 202.19px);
      >.not_found--image {
        @include image-style(263px, 218px, url(../../../assets/images/not_found_page_images.png) no-repeat 50% 50%, contain);
        margin-bottom: 16px;
      }
      >.not_found__title {
        @include NanumSquare-title-22UB($font-color: $neutral-700, $align: center);
      }
      >.not_found__description {
        @include NanumSquare-body-14($font-color:$neutral-500, $align: center);
        margin-bottom: 28px;
      }
    }
  }
}

@media (max-width: 760px) {
  .content {
    &.not_found {
      >.not_found--image {
        @include image-style(213px, 177px, url(../../../assets/images/not_found_page_images.png) no-repeat 50% 50%, contain);
      }
      >.not_found__title {
        @include NanumSquare-title-18UB($font-color: $neutral-700, $align: center);
      }
    }
  }
}