// world.scss
@import '../../../../assets/css/foundation.scss';

.container {
  &.world {
    width: 100%;
    @include flex(flex, $display-flex-column-no, center, flex-start);

    >div {
      width: 100%;

      &.content1 {
        @include flex(flex, $display-flex-column-no, center, center);
        padding: 64px 0;

        >.content1__inner {
          width: 100%;
          max-width: 1170px;

          >.title {
            @include NanumSquare-display-57UB($font-color: $neutral-900, $align: center);
            margin-bottom: 16px;
          }

          >.description {
            @include NanumSquare-headline-24($font-color: $neutral-900, $align: center);
          }
        }
      }

      &.content2 {
        width: 100%;
        padding: 296px 0;
        background: url(../../../../assets/images/world/content2_bg.png) no-repeat 50% 50%;
        background-size: cover;
        @include flex(flex, $display-flex-column-no, center, center);

        >.content2__inner {
          width: 100%;
          max-width: 1170px;
          @include flex(flex, $display-flex-column-no, flex-start, center);

          >.title {
            width: 60%;
            @include NanumSquare-display-48UB($font-color: $neutral-900, $align: left);
            margin-bottom: 24px;
          }

          >.description {
            width: 60%;
            @include NanumSquare-body-22B($font-color: $neutral-500, $align: left);
          }
        }
      }

      &.content3 {
        position: relative;
        width: 100%;
        height: 550px;
        @include flex(flex, $display-flex-column-no, center, center);

        >.content3__inner {
          position: absolute;
          top: -150px;
          width: 100%;
          max-width: 1170px;
          @include flex(flex, $display-flex-row-no, center, space-between);
          gap: 60px;

          >.box {
            flex: 1;

            >.img {
              width: 100%;
              height: 400px;
              border-radius: 28px;
              margin-bottom: 23px;
              box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -5px 6px -2px rgba(16, 24, 40, 0.03);
            }

            >.title {
              width: 100%;
              @include NanumSquare-headline-32UB($font-color: $neutral-700, $align: left);
              margin-bottom: 8px;
            }

            >.description {
              width: 100%;
              @include NanumSquare-body-22B($font-color: $neutral-400, $align: left);
            }

            &:nth-of-type(1) {
              >.img {
                background: url(../../../../assets/images/world/content3_img1.png) no-repeat 50% 50%;
                background-size: cover;
              }
            }

            &:nth-of-type(2) {
              >.img {
                background: url(../../../../assets/images/world/content3_img2.png) no-repeat 50% 50%;
                background-size: cover;
              }
            }
          }
        }
      }

      &.content4 {
        width: 100%;
        padding: 0 0 220px 0;
        @include flex(flex, $display-flex-column-no, center, center);

        >.content4__inner {
          width: 100%;
          max-width: 1170px;
          @include flex(flex, $display-flex-column-no, center, center);

          >.title {
            width: 60%;
            @include NanumSquare-display-48UB($font-color: $neutral-900, $align: center);
            margin-bottom: 60px;
          }

          >.top {
            width: 100%;
            @include flex(flex, $display-flex-row-no, center, space-between);
            margin-bottom: 60px;
            gap: 30px;

            >.swiper {
              position: relative;
              max-width: 770px;
              min-width: 770px;

              >.swiper-wrapper {

                >.swiper-slide {
                  width: 100%;
                  height: 400px;
                  border-radius: 28px;
                  overflow: hidden;

                  > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }

              >.swiper-button-prev,
              >.swiper-button-next {
                z-index: 40;
                position: absolute;
                top: 50%;
                left: 32px;
                width: 40px;
                height: 40px;
                background: url(../../../../assets/images/download/content2_nav_button.png) no-repeat 50% 50%;
                background-size: contain;
                cursor: pointer;

                &.swiper-button-next {
                  left: inherit;
                  right: 32px;
                  transform: rotate(180deg);
                }
                &::after {
                  display: none;
                }
              }

              >.swiper-pagination {
                z-index: 50;
                position: absolute;
                bottom: 20px;
                width: 100%;
                height: 12px;
                @include flex(flex, $display-flex-row-no, center, center);
                gap: 16px;

                >.swiper-pagination-bullet {
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  background: $neutral-300;
                  cursor: pointer;

                  &.swiper-pagination-bullet-active {
                    background: $neutral-600;
                  }
                }
              }
            }

            >.right {
              @include flex(flex, $display-flex-column-no, left, center);

              >.icon {
                width: 47px;
                padding: 4px 12px;
                border-radius: 16px;
                background: $primary-50;
                margin-bottom: 8px;

                >.title {
                  @include NanumSquare-label-12UB($font-color: $primary-700, $align: center);
                }

                &.실내 {
                  background: $success-50;

                  >.title {
                    color: $success-700;
                  }
                }
              }

              >.title {
                @include NanumSquare-headline-32UB($font-color: $neutral-700);
                margin-bottom: 4px;
              }

              >.sub_title {
                @include NanumSquare-body-22B($font-color: $neutral-600);
                margin-bottom: 8px;
              }

              >.description {
                @include NanumSquare-body-18($font-color: $neutral-400);
              }
            }
          }

          >.boxes {
            width: 100%;
            @include flex(flex, $display-flex-row-wrap, center, space-between);
            gap: 30px;

            >.box {
              flex: 1;
              min-width: 370px;
              min-height: 248px;
              border-radius: 20px;
              background: $white;
              border: 1px solid #F2F4F7;
              box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);

              >.img {
                width: 100%;
                height: 187px;
                border-radius: 20px 20px 0 0;
              }

              >.box__inner {
                width: 100%;
                height: 61px;
                @include flex(flex, $display-flex-row-no, center, flex-start);
                padding: 19.5px 16px 17.5px;

                >.icon {
                  padding: 4px 12px;
                  border-radius: 16px;
                  background: $primary-50;
                  margin-right: 8px;

                  >.title {
                    @include NanumSquare-label-12UB($font-color: $primary-700, $align: center);
                  }

                  &.실외 {
                    background: $info-50;

                    >.title {
                      color: $info-700;
                    }
                  }

                  &.실내 {
                    background: $success-50;

                    >.title {
                      color: $success-700;
                    }
                  }
                }

                >.title {
                  @include NanumSquare-title-18UB($font-color: $neutral-700, $align: center);
                }
              }

              &:nth-of-type(1) {
                >.img {
                  background: url(../../../../assets/images/world/content4_img1.png) no-repeat 50% 50%;
                  background-size: cover;
                }
              }

              &:nth-of-type(2) {
                >.img {
                  background: url(../../../../assets/images/world/content4_img2.png) no-repeat 50% 50%;
                  background-size: cover;
                }
              }

              &:nth-of-type(3) {
                >.img {
                  background: url(../../../../assets/images/world/content4_img3.png) no-repeat 50% 50%;
                  background-size: cover;
                }
              }

              &:nth-of-type(4) {
                >.img {
                  background: url(../../../../assets/images/world/content4_img4.png) no-repeat 50% 50%;
                  background-size: cover;
                }
              }

              &:nth-of-type(5) {
                >.img {
                  background: url(../../../../assets/images/world/content4_img5.png) no-repeat 50% 50%;
                  background-size: cover;
                }
              }

              &:nth-of-type(6) {
                >.img {
                  background: url(../../../../assets/images/world/content4_img6.png) no-repeat 50% 50%;
                  background-size: cover;
                }
              }

              &:nth-of-type(7) {
                >.img {
                  background: url(../../../../assets/images/world/content4_img7.png) no-repeat 50% 50%;
                  background-size: cover;
                }
              }

              &:nth-of-type(8) {
                >.img {
                  background: url(../../../../assets/images/world/content4_img8.png) no-repeat 50% 50%;
                  background-size: cover;
                }
              }

              &:nth-of-type(9) {
                >.img {
                  background: url(../../../../assets/images/world/content4_img9.png) no-repeat 50% 50%;
                  background-size: cover;
                }
              }

              &:nth-of-type(10) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .container {
    &.world {
      >div {

        &.content2 {
          padding: 143px 0;

          >.content2__inner {
            padding: 0 24px;

            >.title {
              @include NanumSquare-headline-32UB($font-color: $neutral-900, $align: left);
              margin-bottom: 16px;
            }

            >.description {
              @include NanumSquare-body-18($font-color: $neutral-500, $align: left);
            }
          }
        }

        &.content3 {
          position: inherit;
          height: 528px;

          >.content3__inner {
            position: inherit;
            top: initial;
            padding: 0 24px;
            gap: 30px;

            >.box {
              >.img {
                height: 260px;
              }

              >.title {
                @include NanumSquare-headline-28UB($font-color: $neutral-700, $align: left);
              }

              >.description {
                @include NanumSquare-body-18($font-color: $neutral-400, $align: left);
              }
            }
          }
        }

        &.content4 {
          padding: 101px 0;

          >.content4__inner {
            padding: 0 24px;

            >.title {
              width: 100%;
              @include NanumSquare-headline-36($font-color: $neutral-900, $align: center);
            }

            >.top {
              @include flex(flex, $display-flex-column-no, center, center);
              gap: 23px;
  
              >.swiper {
                position: relative;
                min-width: 100%;
                max-width: 100%;
  
                >.swiper-wrapper {
  
                  >.swiper-slide {
                    height: 400px;
                  }
                }
  
                >.swiper-button-prev,
                >.swiper-button-next {
                  left: 16px;
                  &.swiper-button-next {
                    right: 16px;
                  }
                }
              }
  
              >.right {
  
                >.title {
                  @include NanumSquare-headline-28UB($font-color: $neutral-700);
                  margin-bottom: 4px;
                }
              }
            }

            >.boxes {
              gap: 24px;

              >.box {
                min-width: 348px;
                min-height: 222px;

                >.img {
                  height: 174px;
                }

                >.box__inner {
                  height: 48px;
                  padding: 12px 8px;

                  >.title {
                    @include NanumSquare-title-16UB($font-color: $neutral-700, $align: center);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .container {
    &.world {
      >div {
        &.content1 {
          padding: 48px 0;

          >.content1__inner {
            padding: 0 15px;

            >.title {
              @include NanumSquare-headline-36($font-color: $neutral-900, $align: center);
            }

            >.description {
              @include NanumSquare-body-18($font-color: $neutral-900, $align: center);
            }
          }
        }

        &.content2 {
          height: 500px;
          padding: 40px 0;
          background: url(../../../../assets/images/world/content2_bg_mobile.png) no-repeat 50% 0;
          background-size: cover;
          justify-content: flex-end;

          >.content2__inner {
            padding: 0 15px;

            >.title {
              width: 100%;
              @include NanumSquare-headline-28UB($font-color: $neutral-900, $align: left);
              margin-bottom: 16px;
            }

            >.description {
              width: 100%;
              @include NanumSquare-body-18($font-color: $neutral-500, $align: left);
            }
          }
        }

        &.content3 {
          position: inherit;
          height: inherit;
          padding: 60px 0;

          >.content3__inner {
            position: inherit;
            top: initial;
            padding: 0 15px;
            gap: 30px;
            @include flex(flex, $display-flex-column-no, center, center);

            >.box {
              width: 100%;

              >.img {
                height: 260px;
              }

              >.title {
                @include NanumSquare-title-22UB($font-color: $neutral-700, $align: left);
              }

              >.description {
                @include NanumSquare-body-16UB($font-color: $neutral-400, $align: left);
              }
            }
          }
        }

        &.content4 {
          padding: 101px 0;

          >.content4__inner {
            padding: 0 15px;

            >.title {
              width: 100%;
              @include NanumSquare-headline-28UB($font-color: $neutral-900, $align: center);
            }

            >.top {
              @include flex(flex, $display-flex-column-no, center, center);
              gap: 23px;
  
              >.swiper {
                position: relative;
                min-width: 100%;
                max-width: 100%;
  
                >.swiper-wrapper {
  
                  >.swiper-slide {
                    height: 220px;
                  }
                }

                > .swiper-pagination {
                  display: none;
                }
              }
  
              >.right {
  
                >.title {
                  @include NanumSquare-title-22UB($font-color: $neutral-700);
                }

                >.sub_title {
                  @include NanumSquare-body-18($font-color: $neutral-700);
                }

                >.description {
                  @include NanumSquare-body-16UB($font-color: $neutral-400);
                }
              }
            }

            >.boxes {
              gap: 24px;

              >.box {
                min-width: 348px;
                min-height: 205px;

                >.img {
                  height: 165px;
                }

                >.box__inner {
                  height: 40px;
                  padding: 8px;

                  >.title {
                    @include NanumSquare-title-14UB($font-color: $neutral-700, $align: center);
                  }
                }

                &:last-of-type {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}