// update.scss

@import '../../../../assets/css/foundation.scss';

.update {
  &__inner {
    > .year_box {
      @include flex(flex, $display-flex-row-no, center, center);
      margin-top: 56px;
      margin-bottom: 40px;
      > .prev,
      > .next {
        width: 40px;
        height: 40px;
        cursor: pointer;
        background: $white;
        border: 1px solid $neutral-300;
        border-radius: 50%;
        @include flex(flex, $display-flex-column-no, center, center);
        > svg {
          width: 24px;
          height: 24px;
          > path {
            stroke: $neutral-400;
          }
        }
        &:hover {
          background: $neutral-50;
          > svg {
            > path {
              stroke: $neutral-600;
            }
          }
        }
        &:focus {
          background: $primary-50;
          border: 4px solid $primary-100;
          > svg {
            > path {
              stroke: $neutral-600;
            }
          }
        }
      }
      > .year {
        @include NanumSquare-headline-32UB($font-color: $black);
        margin: 0 24px;
      }
    }
    > .board {
      padding: 0;
      border-bottom: none;
    }
  }
}

@media (max-width: 1402px) {
  .update {
    &__inner {
      > .year_box {
        > .year {
          @include NanumSquare-headline-28UB($font-color: $black);
        }
      }
    }
  }
}