// contentFooter.scss

@import '../../../../assets/css/foundation.scss';

.content_footer {
  z-index: 30;
  position: relative;
  width: calc(100%);
  // border-top: 1px solid $neutral-200;
  padding: 25px 30px;
  background: $primary-500;
  @include flex(flex, $display-flex-column-no, center, center);

  > .content_footer__inner {
    width: 1170px;
    @include flex(flex, $display-flex-column-no, center, center);

    > .logo {
      width: 100%;
      height: 22px;
      background: url(../../../../assets/images/logo.png) no-repeat 0 50%;
      background-size: contain;
      cursor: pointer;
    }
    > .box {
      width: 100%;
      @include flex(flex, $display-flex-row-no, flex-end, space-between);
      margin-top: 21px;
      > .left {
        @include flex(flex, $display-flex-column-no, flex-start, center);
        > .footer_menu {
          @include flex(flex, $display-flex-row-no, center, center);
          gap: 25px;
          margin-bottom: 8px;
          > li {
            position: relative;
            @include NanumSquare-label-12UB($font-color: $neutral-25, $align: center);
            cursor: pointer;
            &::after {
              position: absolute;
              top: calc(50% - 4px);
              right: -12px;
              content: "";
              width: 1px;
              height: 8px;
              background: $neutral-25;
            }
            &:last-of-type {
              &::after {
                display: none;
              }
            }
          }
        }
        > .copyright {
          @include NanumSquare-body-12($font-color: $neutral-25, $align: center);
        }
      }
      > .right {
        @include flex(flex, $display-flex-column-no, flex-end, center);
        gap: 8px;
        > .sns_box {
          @include flex(flex, $display-flex-row-no, center, center);
          gap: 10px;
          > .sns_button {
            width: 36px;
            height: 36px;
            cursor: pointer;
            > svg {
              width: 100%;
              height: 100%;
              > path {
                fill: $white;
              }
            }
          }
        }
        > .language_box {
          > .lang {
            @include NanumSquare-label-14($font-color: $primary-300, $align: center);
            &.on {
              color: $primary-800;
            }
            &::after {
              top: calc(50% - 6px);
              background-color: $primary-300;
              height: 12px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1450px) {
  .content_footer {
    z-index: 30;
    position: relative;
    width: calc(100%);
    // border-top: 1px solid $neutral-200;
    padding: 30px;
    background: $primary-500;
    @include flex(flex, $display-flex-column-no, center, center);
  
    > .content_footer__inner {
      width: 100%;
      @include flex(flex, $display-flex-column-no, center, center);
  
      > .logo {
        width: 100%;
        height: 22px;
        background: url(../../../../assets/images/logo.png) no-repeat 0 50%;
        background-size: contain;
      }
      > .box {
        width: 100%;
        @include flex(flex, $display-flex-column-re-no, center, space-between);
        margin-top: 16px;
        > .left {
          width: 100%;
          @include flex(flex, $display-flex-column-no, flex-start, center);
          > .footer_menu {
            @include flex(flex, $display-flex-row-no, center, center);
            gap: 25px;
            margin-bottom: 8px;
            > li {
              position: relative;
              @include NanumSquare-label-12UB($font-color: $neutral-25, $align: center);
              cursor: pointer;
              &::after {
                position: absolute;
                top: calc(50% - 4px);
                right: -12px;
                content: "";
                width: 1px;
                height: 8px;
                background: $neutral-25;
              }
              &:last-of-type {
                &::after {
                  display: none;
                }
              }
            }
          }
          > .copyright {
            @include NanumSquare-body-12($font-color: $neutral-25, $align: center);
          }
        }
        > .right {
          width: 100%;
          @include flex(flex, $display-flex-row-no, center, space-between);
          margin-bottom: 25px;
          gap: 8px;
          > .sns_box {
            @include flex(flex, $display-flex-row-no, center, center);
            gap: 10px;
            > .sns_button {
              width: 36px;
              height: 36px;
              cursor: pointer;
              > svg {
                width: 100%;
                height: 100%;
                > path {
                  fill: $white;
                }
              }
            }
          }
          > .language_box {
            > .lang {
              @include NanumSquare-label-14($font-color: $primary-300, $align: center);
              &.on {
                color: $primary-800;
              }
              &::after {
                top: calc(50% - 6px);
                background-color: $primary-300;
                height: 12px;
              }
            }
          }
        }
      }
    }
  }
}