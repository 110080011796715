@import '../../../assets/css/foundation.scss';
.content {
  z-index: 10;
  position: relative;
  // width: calc(100vw - 232px);
  width: 100%;
  // margin-left: 232px;
  height: auto;
  // min-height: calc(100vh - 64px);
  min-height: -webkit-fill-available; 
  @include flex(flex, $display-flex-column-no, center, flex-start);
  > .button__studio {
    z-index: 100;
    position: fixed;
    // bottom: 99px;
    bottom: 24px;
    right: 24px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    cursor: pointer;
    transition: bottom .5s linear 0s;
    background: $primary-600 url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.67362 13.1036C6.80416 13.3114 6.02938 13.8045 5.47292 14.5042C4.91647 15.2038 4.61042 16.0697 4.60362 16.9636V20.0736C4.60226 20.137 4.61374 20.2001 4.63738 20.2589C4.66102 20.3178 4.69633 20.3712 4.74118 20.4161C4.78602 20.4609 4.83948 20.4962 4.89833 20.5199C4.95719 20.5435 5.02022 20.555 5.08362 20.5536L8.32362 20.4936C9.04747 20.4948 9.75644 20.2881 10.3662 19.8981C10.9761 19.5081 11.4611 18.9512 11.7636 18.2936C12.0741 17.6739 12.2017 16.9785 12.1314 16.2889C12.0612 15.5994 11.7961 14.944 11.367 14.3996C10.938 13.8552 10.3628 13.4442 9.70873 13.2147C9.05467 12.9852 8.34877 12.9466 7.67362 13.1036Z' fill='white'/%3E%3Cpath d='M19.8138 5.01335C19.3942 4.68246 18.866 4.52052 18.333 4.55938C17.8001 4.59824 17.3009 4.83509 16.9338 5.22335L10.5538 11.6333C10.4671 11.7213 10.4185 11.8398 10.4185 11.9633C10.4185 12.0869 10.4671 12.2054 10.5538 12.2933L12.8038 14.5533C12.8917 14.6401 13.0103 14.6887 13.1338 14.6887C13.2573 14.6887 13.3758 14.6401 13.4638 14.5533L19.9538 8.08335C20.1461 7.8902 20.2981 7.66081 20.4011 7.40848C20.5041 7.15614 20.556 6.88588 20.5538 6.61335C20.5609 6.30784 20.4979 6.00478 20.3696 5.72742C20.2413 5.45006 20.0512 5.20579 19.8138 5.01335Z' fill='white'/%3E%3C/svg%3E ") no-repeat 50% 50%;
    &:hover {
      background: $primary-400 url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.67362 13.1036C6.80416 13.3114 6.02938 13.8045 5.47292 14.5042C4.91647 15.2038 4.61042 16.0697 4.60362 16.9636V20.0736C4.60226 20.137 4.61374 20.2001 4.63738 20.2589C4.66102 20.3178 4.69633 20.3712 4.74118 20.4161C4.78602 20.4609 4.83948 20.4962 4.89833 20.5199C4.95719 20.5435 5.02022 20.555 5.08362 20.5536L8.32362 20.4936C9.04747 20.4948 9.75644 20.2881 10.3662 19.8981C10.9761 19.5081 11.4611 18.9512 11.7636 18.2936C12.0741 17.6739 12.2017 16.9785 12.1314 16.2889C12.0612 15.5994 11.7961 14.944 11.367 14.3996C10.938 13.8552 10.3628 13.4442 9.70873 13.2147C9.05467 12.9852 8.34877 12.9466 7.67362 13.1036Z' fill='white'/%3E%3Cpath d='M19.8138 5.01335C19.3942 4.68246 18.866 4.52052 18.333 4.55938C17.8001 4.59824 17.3009 4.83509 16.9338 5.22335L10.5538 11.6333C10.4671 11.7213 10.4185 11.8398 10.4185 11.9633C10.4185 12.0869 10.4671 12.2054 10.5538 12.2933L12.8038 14.5533C12.8917 14.6401 13.0103 14.6887 13.1338 14.6887C13.2573 14.6887 13.3758 14.6401 13.4638 14.5533L19.9538 8.08335C20.1461 7.8902 20.2981 7.66081 20.4011 7.40848C20.5041 7.15614 20.556 6.88588 20.5538 6.61335C20.5609 6.30784 20.4979 6.00478 20.3696 5.72742C20.2413 5.45006 20.0512 5.20579 19.8138 5.01335Z' fill='white'/%3E%3C/svg%3E ") no-repeat 50% 50%;
    }
    &.on {
      bottom: 95px;
    }
  }
}

@media (max-width: 1450px) {
  .content {
    width: 100vw;
    overflow-y: hidden;
    margin-left: 0;
    min-height: calc(100vh - 56px);
    min-height: -webkit-fill-available; 
    > .button__studio {
      // bottom: 164px;
      bottom: 24px;
      &.on {
        bottom: 168px;
      }
    }
  }
}

@media (max-width: 760px) {
  .content {
    width: 100%;
    margin-left: 0;
    min-height: calc(100vh - 56px);
    min-height: -webkit-fill-available; 
    > .button__studio {
      // bottom: 176px;
      bottom: 24px;
      &.on {
        bottom: 168px;
      }
    }
    > .container {
      &.apk {
        max-height: calc(100% - 211px);
        overflow-y: hidden;
      }
    }
  }
}

@keyframes floating {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(25px);
  }
}