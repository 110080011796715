// studio.scss

@import '../../../assets/css/foundation.scss';

.studio {
  width: 100%;

  &__inner {
    width: 100%;

    >.content1 {
      width: 100%;
      height: 888px;
      background: url(../../../assets/images/studio_content1_bg_pc.png) no-repeat 50% 50%;
      background-size: cover;
      @include flex(flex, $display-flex-column-no, center, center);

      >.content1__inner {
        position: relative;
        width: 1170px;
        @include flex(flex, $display-flex-row-no, center, space-between);

        >.left {
          z-index: 100;
          position: absolute;
          left: 0;
          @include flex(flex, $display-flex-column-no, flex-start, center);

          >.title {
            @include NanumSquare-display-57UB($font-color: $white);
            margin-bottom: 30px;
          }

          >.normal_button {
            padding: 16px 47.5px;
          }
        }

        >.image {
          z-index: 50;
          position: absolute;
          top: -380px;
          right: -200px;
          background: url(../../../assets/images/studio_content1_img.png) no-repeat 50% 50%;
          background-size: contain;
          width: 864px;
          height: 702px;
        }


      }
    }

    .text__box {
      >.title {
        @include NanumSquare-display-45UB($font-color: $neutral-800);
        margin-bottom: 20px;
      }

      >.description {
        @include NanumSquare-body-22B($font-color: $neutral-500, $align: center);
        margin-bottom: 60px;
      }
    }

    >.content2 {
      width: 100%;
      padding-top: 160px;
      @include flex(flex, $display-flex-column-no, center, center);
      margin-bottom: 160px;

      >.text__box {
        >.title {
          margin-bottom: 20px;
        }

        >.description {
          margin-bottom: 60px;
        }
      }

      >.image__box {
        width: 100%;
        height: 990px;
        background: url(../../../assets/images/studio_content2_img.png) no-repeat 50% 50%;
        background-size: contain;
      }
    }

    >.content3 {
      width: 100%;
      @include flex(flex, $display-flex-column-no, center, center);
      margin-bottom: 60px;

      >.normal_button {
        padding: 16px 45px;
      }
    }

    >.content4 {
      margin-bottom: 200px;

      >.content4__inner {
        width: 1170px;
        padding: 80px 120px;
        background: $neutral-50;
        border-radius: 52px;
        @include flex(flex, $display-flex-row-no, center, space-between);

        >.box {
          @include flex(flex, $display-flex-column-no, center, center);
          max-width: 184px;

          >.normal_button {
            margin-bottom: 24px;
            cursor: default;
          }

          >.title {
            @include NanumSquare-title-22UB($font-color: $neutral-900, $align: center);
            margin-bottom: 8px;
          }

          >.description {
            @include NanumSquare-body-16UB($font-color: $neutral-600, $align: center);
          }
        }

        >.arrow {
          width: 20px;
          height: 20px;
          background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12H19M19 12L12 5M19 12L12 19' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ") no-repeat 50% 50%;
        }
      }
    }
  }
}

@media (max-width: 1402px) {
  .studio {

    &__inner {

      >.content1 {
        height: 523px;
        background: url(../../../assets/images/studio_content1_bg_tablet.png) no-repeat 50% 50%;
        background-size: cover;

        >.content1__inner {
          width: 691px;

          >.left {
            >.title {
              @include NanumSquare-headline-32UB($font-color: $white);
            }

            >.normal_button {
              padding: 12px 38px;
            }
          }

          >.image {
            top: -240px;
            right: -150px;
            width: 563px;
            height: 457px;
          }

        }
      }

      .text__box {
        >.title {
          @include NanumSquare-headline-32UB($font-color: $neutral-800, $align: center);
        }

        >.description {
          @include NanumSquare-title-16UB($font-color: $neutral-500, $align: center);
        }
      }

      >.content2 {
        padding-top: 100px;
        margin-bottom: 120px;

        >.image__box {
          height: 450px;
        }
      }

      >.content3 {
        >.normal_button {
          padding: 10px 47.5px;
        }
      }

      >.content4 {
        width: 100%;
        margin-bottom: 120px;
        padding: 0 24px;

        >.content4__inner {
          width: 100%;
          // padding: 60px 38.5px;
          // border: 1px solid red;

          >.box {
            max-width: 164px;

            >.normal_button {
              margin-bottom: 24px;
            }

            >.title {
              @include NanumSquare-label-16UB($font-color: $neutral-900, $align: center);
            }

            >.description {
              @include NanumSquare-body-14($font-color: $neutral-600, $align: center);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .studio {

    &__inner {
      padding-bottom: 0;

      >.content1 {
        background: url(../../../assets/images/studio_content1_bg_mobile.png) no-repeat 50% 50%;
        background-size: cover;

        >.content1__inner {
          width: 310px;
          @include flex(flex, $display-flex-column-no, center, center);

          >.left {
            top: 60px;
            left: 25px;
            @include flex(flex, $display-flex-column-no, center, center);

            >.title {
              @include NanumSquare-title-22UB($font-color: $white, $align: center);
              margin-bottom: 24px;
            }

            >.normal_button {
              padding: 10px 38px;
            }
          }

          >.image {
            top: -280px;
            right: -50px;
            width: 411px;
            height: 334x;
          }

        }
      }

      .text__box {
        >.title {
          @include NanumSquare-title-18UB($font-color: $neutral-800, $align: center);
        }

        >.description {
          @include NanumSquare-body-14($font-color: $neutral-500, $align: center);
        }
      }

      >.content2 {
        padding-top: 60px;
        margin-bottom: 90px;

        >.image__box {
          height: 216px;
          background-size: cover;
        }
      }

      >.content3 {
        >.text__box {
          >.description {
            margin-bottom: 24px;
          }
        }

        >.content3 {
          >.normal_button {
            padding: 12px 51px;
          }
        }
      }

      >.content4 {
        margin-bottom: 80px;
        padding: 0 15px;

        >.content4__inner {
          padding: 56px 72.5px;
          @include flex(flex, $display-flex-column-no, center, center);
          gap: 24px;

          >.box {
            max-width: 164px;

            >.normal_button {
              margin-bottom: 24px;
            }

            >.title {
              @include NanumSquare-label-16UB($font-color: $neutral-900, $align: center);
            }

            >.description {
              @include NanumSquare-body-14($font-color: $neutral-600, $align: center);
            }
          }

          >.arrow {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}