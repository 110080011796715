// exchange.scss
@import '../../../../assets/css/foundation.scss';

.container {
  &.exchange {
    width: 100%;
    @include flex(flex, $display-flex-column-no, center, flex-start);
    > div {
      width: 100%;
      &.content1 {
        @include flex(flex, $display-flex-column-no, center, center);
        padding: 64px 0;
        >.content1__inner {
          width: 100%;
          max-width: 1170px;
          >.title {
            @include NanumSquare-display-57UB($font-color: $neutral-900, $align: center);
            margin-bottom: 16px;
          }

          >.description {
            @include NanumSquare-headline-24($font-color: $neutral-900, $align: center);
          }
        }
      }

      &.content2 {
        width: 100%;
        padding: 296px 0;
        background: url(../../../../assets/images/exchange/content2_bg.png) no-repeat 50% 50%;
        background-size: cover;
        @include flex(flex, $display-flex-column-no, center, center);
        > .content2__inner {
          width: 100%;
          max-width: 1170px;
          @include flex(flex, $display-flex-column-no, flex-start, center);
          > .title {
            width: 60%;
            @include NanumSquare-display-48UB($font-color: $white, $align: left);
            margin-bottom: 24px;
          }
          > .description {
            width: 60%;
            @include NanumSquare-body-22B($font-color: $neutral-300, $align: left);
          }
        }
      }

      &.content3 {
        position: relative;
        width: 100%;
        height: 550px;
        @include flex(flex, $display-flex-column-no, center, center);
        >.content3__inner {
          position: absolute;
          top: -150px;
          width: 100%;
          max-width: 1170px;
          @include flex(flex, $display-flex-row-no, center, space-between);
          gap: 60px;
          > .box {
            flex: 1;
            > .img {
              width: 100%;
              height: 400px;
              border-radius: 28px;
              margin-bottom: 23px;
              box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -5px 6px -2px rgba(16, 24, 40, 0.03);
            }
            > .title {
              width: 100%;
              @include NanumSquare-headline-32UB($font-color: $neutral-700, $align: left);
              margin-bottom: 8px;
            }
            > .description {
              width: 100%;
              @include NanumSquare-body-22B($font-color: $neutral-400, $align: left);
            }
            &:nth-of-type(1) {
              > .img {
                background: url(../../../../assets/images/exchange/content3_img1.png) no-repeat 50% 50%;
                background-size: cover;
              }
            }
            &:nth-of-type(2) {
              > .img {
                background: url(../../../../assets/images/exchange/content3_img2.png) no-repeat 50% 50%;
                background-size: cover;
              }
            }
          }
        }
      }

      &.content4 {
        width: 100%;
        padding: 0 0 220px 0;
        @include flex(flex, $display-flex-column-no, center, center);
        > .content4__inner {
          width: 100%;
          max-width: 1170px;
          @include flex(flex, $display-flex-column-no, center, center);
          > .title {
            width: 100%;
            @include NanumSquare-display-48UB($font-color: $neutral-900, $align: center);
            margin-bottom: 14px;
          }
          > .description {
            width: 80%;
            @include NanumSquare-body-22B($font-color: $neutral-400, $align: center);
            margin-bottom: 60px;
          }
          > .boxes {
            width: 100%;
            @include flex(flex, $display-flex-row-wrap, center, space-between);
            gap: 30px;
            > .box {
              position: relative;
              flex: 1;
              min-width: 370px;
              min-height: 346px;
              padding: 45px;
              border-radius: 20px;
              background: $white;
              border: 1px solid #F2F4F7;
              box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);
              > .title {
                @include NanumSquare-title-22UB($font-color: $primary-700, $align: center);
                margin-bottom: 30px;
              }
              > .img {
                position: absolute;
              }
              &:nth-of-type(1) {
                > .img {
                  top: calc(50% - 100px);
                  left: calc(50% - 130px);
                  width: 260px;
                  height: 240px;
                  background: url(../../../../assets/images/exchange/content4_img1.png) no-repeat 50% 50%;
                  background-size: contain;
                  &::after {
                    content: "";
                    position: absolute;
                    width: 140px;
                    height: 140px;
                    top: -45px;
                    right: -45px;
                    background: url(../../../../assets/images/exchange/content4_img1-2.png) no-repeat 50% 50%;
                    background-size: contain;
                  }
                  &::before {
                    content: "";
                    position: absolute;
                    width: 162px;
                    height: 162px;
                    bottom: -35px;
                    right: -30px;
                    background: url(../../../../assets/images/exchange/content4_img1-3.png) no-repeat 50% 50%;
                    background-size: contain;
                  }
                }
              }
              &:nth-of-type(2) {
                > .img {
                  top: calc(50% - 125px);
                  left: calc(50% - 160px);
                  width: 300px;
                  height: 300px;
                  background: url(../../../../assets/images/exchange/content4_img2.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }
              &:nth-of-type(3) {
                > .img {
                  top: calc(50% - 80px);
                  left: calc(50% - 146px);
                  width: 292px;
                  height: 210px;
                  background: url(../../../../assets/images/exchange/content4_img3.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .container {
    &.exchange {
      > div {
  
        &.content2 {
          padding: 143px 0;
          background: url(../../../../assets/images/exchange/content2_bg_tablet.png) no-repeat 50% 50%;
          background-size: cover;
          > .content2__inner {
            padding: 0 24px;
            > .title {
              @include NanumSquare-headline-32UB($font-color: $white, $align: left);
              margin-bottom: 16px;
            }
            > .description {
              @include NanumSquare-body-18($font-color: $neutral-300, $align: left);
            }
          }
        }
  
        &.content3 {
          position: inherit;
          height: 528px;
          >.content3__inner {
            position: inherit;
            top: initial;
            padding: 0 24px;
            gap: 30px;
            > .box {
              > .img {
                height: 260px;
              }
              > .title {
                @include NanumSquare-headline-28UB($font-color: $neutral-700, $align: left);
              }
              > .description {
                @include NanumSquare-body-18($font-color: $neutral-400, $align: left);
              }
            }
          }
        }
  
        &.content4 {
          padding: 101px 0;
          > .content4__inner {
            padding: 0 24px;
            > .title {
              width: 100%;
              @include NanumSquare-headline-36($font-color: $neutral-900, $align: center);
            }
            > .description {
              width: 100%;
              @include NanumSquare-body-18($font-color: $neutral-400, $align: center);
            }
            > .boxes {
              gap: 30px;
              > .box {
                min-width: 220px;
                min-height: 206px;
                padding: 27px;
                > .title {
                  @include NanumSquare-label-16UB($font-color: $primary-700, $align: center);
                }
                &:nth-of-type(1) {
                  > .img {
                    top: calc(50% - 60px);
                    left: calc(50% - 77.5px);
                    width: 155px;
                    height: 143px;
                    &::after {
                      top: -25px;
                      right: -25px;
                      width: 83px;
                      height: 83px;
                    }
                    &::before {
                      bottom: -20px;
                      right: -20px;
                      width: 96px;
                      height: 96px;
                    }
                  }
                }
                &:nth-of-type(2) {
                  > .img {
                    top: calc(50% - 70px);
                    left: calc(50% - 93px);
                    width: 178px;
                    height: 178px;
                  }
                }
                &:nth-of-type(3) {
                  > .img {
                    top: calc(50% - 45px);
                    left: calc(50% - 86.5px);
                    width: 173px;
                    height: 125px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .container {
    &.exchange {
      > div {
        &.content1 {
          padding: 48px 0;
          > .content1__inner {
            padding: 0 15px;
            > .title {
              @include NanumSquare-headline-36($font-color: $neutral-900, $align: center);
            }
            > .description {
              @include NanumSquare-body-18($font-color: $neutral-900, $align: center);
            }
          }
        }
  
        &.content2 {
          height: 700px;
          padding: 60px 0;
          background: url(../../../../assets/images/exchange/content2_bg_mobile.png) no-repeat 50% 55%;
          background-size: cover;
          justify-content: flex-start;
            
          > .content2__inner {
            padding: 0 15px;
            > .title {
              width: 100%;
              @include NanumSquare-headline-28UB($font-color: $white, $align: center);
              margin-bottom: 12px;
            }
            > .description {
              width: 100%;
              @include NanumSquare-body-18($font-color: $neutral-300, $align: center);
            }
          }
        }
  
        &.content3 {
          position: inherit;
          height: inherit;
          padding: 60px 0;
          >.content3__inner {
            position: inherit;
            top: initial;
            padding: 0 15px;
            gap: 30px;
            @include flex(flex, $display-flex-column-no, center, center);
            > .box {
              width: 100%;
              > .img {
                height: 260px;
              }
              > .title {
                @include NanumSquare-title-22UB($font-color: $neutral-700, $align: left);
              }
              > .description {
                @include NanumSquare-body-16UB($font-color: $neutral-400, $align: left);
              }
            }
          }
        }
  
        &.content4 {
          padding: 101px 0;
          > .content4__inner {
            padding: 0 15px;
            > .title {
              width: 100%;
              @include NanumSquare-headline-28UB($font-color: $neutral-900, $align: center);
            }
            > .description {
              width: 100%;
              @include NanumSquare-body-16UB($font-color: $neutral-400, $align: center);
            }
            > .boxes {
              gap: 24px;
              > .box {
                min-width: 348px;
                min-height: 205px;
                > .img {
                  height: 165px;
                }
                  > .title {
                    @include NanumSquare-label-16UB($font-color: $primary-700, $align: center);
                  }
                }
            }
          }
        }
      }
    }
  }
}