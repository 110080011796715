// apk.scss
@import '../../../../assets/css/foundation.scss';

.container {
  &.apk {
    position: relative;
    background: url(../../../../assets/images/apk_background.png) no-repeat 50% 50%;
    background-size: cover;
    @include flex(flex, $display-flex-column-no, center, center);
    &::after {
      z-index: 10;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.6;
    }
    > .container__inner {
      z-index: 20;
      position: inherit;
      width: 100%;
      padding: 0 15px;
      @include flex(flex, $display-flex-column-no, center, center);
      
      > .center {
        @include flex(flex, $display-flex-column-no, center, center);
        > .logo {
          width: 330px;
          height: 36px;
          background: url(../../../../assets/images/apk_logo.png) no-repeat 50% 50%;
          background-size: contain;
          margin-bottom: 16px;
        }
        > .description {
          width: 100%;
          @include NanumSquare-title-22UB($font-color: $white, $align: center);
          margin-bottom: 32px;
        }
        > .normal_button {
          width: auto;
          padding: 12px 18px;
          > .icon {
            width: 20px;
            height: 20px;
            background: url(../../../../assets/images/download.png) no-repeat 50% 50%;
            // background-size: contain;
            margin-right: 8px;
            &::after,
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}