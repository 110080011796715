// club.scss
@import '../../../../assets/css/foundation.scss';

.container {
  &.club {
    width: 100%;
    @include flex(flex, $display-flex-column-no, center, flex-start);
    > div {
      width: 100%;
      &.content1 {
        @include flex(flex, $display-flex-column-no, center, center);
        padding: 64px 0;
        >.content1__inner {
          width: 100%;
          max-width: 1170px;
          >.title {
            @include NanumSquare-display-57UB($font-color: $neutral-900, $align: center);
            margin-bottom: 16px;
          }

          >.description {
            @include NanumSquare-headline-24($font-color: $neutral-900, $align: center);
          }
        }
      }

      &.content2 {
        width: 100%;
        padding: 296px 0;
        background: url(../../../../assets/images/club/content2_bg.png) no-repeat 50% 50%;
        background-size: cover;
        @include flex(flex, $display-flex-column-no, center, center);
        > .content2_inner {
          width: 100%;
          max-width: 1170px;
          @include flex(flex, $display-flex-column-no, flex-start, center);
          > .title {
            width: 60%;
            @include NanumSquare-display-48UB($font-color: $white, $align: left);
            margin-bottom: 24px;
          }
          > .description {
            width: 60%;
            @include NanumSquare-body-22B($font-color: $neutral-300, $align: left);
          }
        }
      }

      &.content3 {
        position: relative;
        width: 100%;
        height: 550px;
        @include flex(flex, $display-flex-column-no, center, center);
        >.content3__inner {
          position: absolute;
          top: -150px;
          width: 100%;
          max-width: 1170px;
          @include flex(flex, $display-flex-row-no, center, space-between);
          gap: 60px;
          > .box {
            flex: 1;
            > .img {
              width: 100%;
              height: 400px;
              border-radius: 28px;
              margin-bottom: 23px;
              box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -5px 6px -2px rgba(16, 24, 40, 0.03);
            }
            > .title {
              width: 100%;
              @include NanumSquare-headline-32UB($font-color: $neutral-700, $align: left);
              margin-bottom: 8px;
            }
            > .description {
              width: 100%;
              @include NanumSquare-body-22B($font-color: $neutral-400, $align: left);
            }
            &:nth-of-type(1) {
              > .img {
                background: url(../../../../assets/images/club/content3_img1.png) no-repeat 50% 50%;
                background-size: cover;
              }
            }
            &:nth-of-type(2) {
              > .img {
                background: url(../../../../assets/images/club/content3_img2.png) no-repeat 50% 50%;
                background-size: cover;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .container {
    &.club {
      > div {
  
        &.content2 {
          padding: 143px 0;
          > .content2_inner {
            padding: 0 24px;
            > .title {
              @include NanumSquare-headline-32UB($font-color: $white, $align: left);
              margin-bottom: 16px;
            }
            > .description {
              @include NanumSquare-body-18($font-color: $neutral-300, $align: left);
            }
          }
        }
  
        &.content3 {
          position: inherit;
          height: 528px;
          >.content3__inner {
            position: inherit;
            top: initial;
            padding: 0 24px;
            gap: 30px;
            > .box {
              > .img {
                height: 260px;
              }
              > .title {
                @include NanumSquare-headline-28UB($font-color: $neutral-700, $align: left);
              }
              > .description {
                @include NanumSquare-body-18($font-color: $neutral-400, $align: left);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .container {
    &.club {
      > div {
        &.content1 {
          padding: 48px 0;
          > .content1__inner {
            padding: 0 15px;
            > .title {
              @include NanumSquare-headline-36($font-color: $neutral-900, $align: center);
            }
            > .description {
              @include NanumSquare-body-18($font-color: $neutral-900, $align: center);
            }
          }
        }
  
        &.content2 {
          height: 700px;
          padding: 60px 0;
          background: url(../../../../assets/images/club/content2_bg_mobile.png) no-repeat 50% 55%;
          background-size: cover;
          justify-content: flex-start;
            
          > .content2_inner {
            padding: 0 15px;
            > .title {
              width: 100%;
              @include NanumSquare-headline-28UB($font-color: $white, $align: center);
              margin-bottom: 12px;
            }
            > .description {
              width: 100%;
              @include NanumSquare-body-18($font-color: $neutral-300, $align: center);
            }
          }
        }
  
        &.content3 {
          position: inherit;
          height: inherit;
          padding: 60px 0;
          >.content3__inner {
            position: inherit;
            top: initial;
            padding: 0 15px;
            gap: 30px;
            @include flex(flex, $display-flex-column-no, center, center);
            > .box {
              width: 100%;
              > .img {
                height: 260px;
              }
              > .title {
                @include NanumSquare-title-22UB($font-color: $neutral-700, $align: left);
              }
              > .description {
                @include NanumSquare-body-16UB($font-color: $neutral-400, $align: left);
              }
            }
          }
        }
      }
    }
  }
}