@import '../../../../assets/css/foundation.scss';

// header
.header {
  z-index: 40;
  position: fixed;
  width: 100%;
  @include flex(flex, $display-flex-row-no, flex-start, space-between);
  background: $primary-500;
  padding: 0 32px;
  transition: all .2s linear 0s;
  > .left {
    @include flex(flex, $display-flex-row-no, flex-start, center);
    > .logo {
      width: 199px;
      height: 22px;
      background: url(../../../../assets/images/logo.png) no-repeat 50% 50%;
      background-size: contain;
      text-indent: -9999px;
      overflow: hidden;
      margin-right: 64px;
      margin-top: 21px;
      cursor: pointer;
    }
    ul,
    li {
      box-sizing: border-box
    }
    > .gnb {
      @include flex(flex, $display-flex-row-no, flex-start, center);
      padding-top: 20px;
      padding-bottom: 20px;
      > .gnb_li_deps1 {
        max-width: 133px;
        padding: 0 36px;
        @include NanumSquare-label-16UB($font-color: $white, $align: left);
        line-height: 150%;
        cursor: pointer;
        // border: 1px solid red;
        > .gnb_ul_deps2 {
          margin: 16px 0 26px;
          display: none;
          > .gnb_li_deps2 {
            padding: 8px 0;
            @include NanumSquare-label-12UB($font-color: $white, $align: left);
            cursor: pointer;
            white-space: nowrap;
          }
        }
        &.on {
          // border: 1px solid red
        }
      }
    }
  }

  > .right {
    @include flex(flex, $display-flex-row-re-no, center, space-between);
    gap: 8px;
    padding-top: 15px;

    // my menu
    >.avatar {
      position: relative;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: none;
      background: url(../../../../assets/images/avatar.png) no-repeat 50% 50%;
      background-size: contain;

      >p {
        display: none;
      }

      >.profile {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        cursor: pointer;
        &.none {
          background: url(../../../../assets/images/avatar.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }

      &:hover {
        width: 32px;
        height: 32px;
        border: none;
      }

      >.my_menu_box {
        position: absolute;
        display: none;
        top: calc(100% + 5px);
        right: 0;
        min-width: 240px;
        background: $white;
        border: 1px solid $neutral-200;
        border-radius: 8px;
        cursor: default;

        >.my_menu_top {
          width: 100%;
          @include flex(flex, $display-flex-row-no, center, flex-start);
          padding: 12px 16px;

          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 39px;
            left: 39px;
            width: 10px;
            height: 10px;
            background: $success-500;
            border: 1.5px solid $white;
            border-radius: 50%;
          }

          >.avatar {
            position: relative;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 12px;
            &.none {
              background: url(../../../../assets/images/avatar.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }

          >.my_menu_top_right {
            >.nickname {
              @include text-style($neutral-700, 14px, 700, 20px, left);
            }

            >.email {
              @include text-style($neutral-500, 11px, 700, 18px, left);
            }
          }
        }

        >.menu {
          width: 100%;
          @include flex(flex, $display-flex-column-no, center, center);
          box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -5px 6px -2px rgba(16, 24, 40, 0.03);

          >li {
            width: 100%;
            padding: 14px 16px;
            box-sizing: border-box;
            // @include NanumSquare-body-14B;
            @include text-style($neutral-700, 14px, 400, 22px, left);
            cursor: pointer;

            &:last-of-type {
              border-top: 1px solid $neutral-200;
            }

            &:hover {
              background: $neutral-50;
            }
          }
        }

        &.on {
          @include flex(flex, $display-flex-column-no, center, center);
        }
      }
    }

    // play button
    .normal_button {
      >.icon {
        &.trailing {
          background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 18L15 12L9 6' stroke='%236941C6' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
          background-size: contain;


          &::after,
          &::before {
            display: none;
          }
        }
      }
    }
  }

  &.on {
    > .left {
      > .gnb {
        > .gnb_li_deps1 {
          > .gnb_ul_deps2 {
            @include flex(flex, $display-flex-column-no, flex-start, center);
          }
        }
      }
    }
  }
}