@import "../../../../assets/css/foundation.scss";

.container {
  width: 100%;
  // min-height: calc(100vh - 141px);
  min-height: calc((var(--vh, 1vh) * 100) - 56px);
  overflow-y: hidden;

  &.zemiverse {
    > .container__inner {
      width: 100%;

      .title {
        @include NanumSquare-display-48UB($font-color: $primary-700, $align: center);
      }

      .description {
        @include NanumSquare-body-22B($font-color: $neutral-700, $align: center);
      }

      > .content1 {
        position: relative;
        width: 100%;
        height: 850px;
        @include flex(flex, $display-flex-column-no, center, center);
        background: url(../../../../assets/images/apk_background.png) no-repeat 50% 50%;
        background-size: cover;

        &::after {
          z-index: 10;
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          width: 100%;
          height: 100%;
          background: #000;
          opacity: 0.6;
        }

        > .content1__inner {
          z-index: 20;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          @include flex(flex, $display-flex-column-no, center, center);

          > .title,
          > .description {
            @include NanumSquare-display-57UB($font-color: $white);
          }

          > .normal_button {
            margin-top: 40px;
          }
        }
        > .box__scroll__down {
          display: none;
          z-index: 20;
          position: absolute;
          bottom: 0;
          left: calc(50% - 23.5px);
          width: 47px;
          height: 84px;

          > p {
            width: 100%;
            @include NanumSquare-label-12UB($font-color: $white, $align: center);
          }

          > .box {
            position: absolute;
            bottom: 0;
            left: calc(50% - 1px);
            width: 2px;
            height: 62px;
            background: white;
            overflow: hidden;

            > .bar {
              position: absolute;
              top: -13px;
              width: 100%;
              height: 13px;
              background: $secondary-500;
              animation: scrolldown 2s linear 0.5s infinite;
            }
          }
          &.top {
            display: block;
          }
        }
      }

      > .content2 {
        width: 100%;
        padding: 160px 0;

        > .content2__inner {
          width: 100%;
          @include flex(flex, $display-flex-column-no, center, center);

          > .description {
            margin: 16px 0 24px;
          }

          > .swiper {
            width: 100%;
            margin-bottom: 40px;

            > .swiper-wrapper {
              // width: 100%;
              @include flex(flex, $display-flex-row-no, center, flex-start);

              > .swiper-slide {
                // width: 499px;
                height: 286px;
                // min-width: 499px;
                // min-height: 286px;
                border-radius: 16px;

                &:nth-of-type(1) {
                  background: url(../../../../assets/images/zemiverse_2306/content2_img1.png) no-repeat 50% 50%;
                  background-size: cover;
                }

                &:nth-of-type(2) {
                  background: url(../../../../assets/images/zemiverse_2306/content2_img2.png) no-repeat 50% 50%;
                  background-size: cover;
                }

                &:nth-of-type(3) {
                  background: url(../../../../assets/images/zemiverse_2306/content2_img6.png) no-repeat 50% 50%;
                  background-size: cover;
                }

                &:nth-of-type(4) {
                  background: url(../../../../assets/images/zemiverse_2306/content2_img4.png) no-repeat 50% 50%;
                  background-size: cover;
                }

                &:nth-of-type(5) {
                  background: url(../../../../assets/images/zemiverse_2306/content2_img5.png) no-repeat 50% 50%;
                  background-size: cover;
                }

                &:nth-of-type(6) {
                  background: url(../../../../assets/images/zemiverse_2306/content2_img3.png) no-repeat 50% 50%;
                  background-size: cover;
                }
                &.swiper-slide-active {
                  // width: 700px;
                  // height: 375px;
                  height: 400px;
                  padding-top: 25px;
                  // min-width: 700px;
                  // min-height: 400px;
                }
              }
            }

            > .swiper-pagination {
              width: 100%;
              height: 12px;
              @include flex(flex, $display-flex-row-no, center, center);
              gap: 16px;
              margin-top: 24px;

              > .swiper-pagination-bullet {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: $neutral-300;
                cursor: pointer;

                &.swiper-pagination-bullet-active {
                  background: $neutral-600;
                }
              }
            }
          }
        }
      }

      > .content3 {
        width: 100%;
        padding: 106px 0;
        background: radial-gradient(41.2% 41.2% at 50% 50%, rgba(255, 255, 255, 0.48) 0%, rgba(154, 75, 255, 0.48) 100%),
          #7f56d9;
        background-blend-mode: lighten, normal;

        > .content3__inner {
          @include flex(flex, $display-flex-column-no, center, center);

          > .title,
          > .description {
            color: $white;

            &.description {
              margin: 24px 0 67px;
            }
          }

          > .player__box {
            width: 832px;
            height: 480px;
            border-radius: 30px;
            @include flex(flex, $display-flex-column-no, center, center);
            filter: drop-shadow(0px 16px 30px rgba(0, 0, 0, 0.2));

            &::after {
              position: absolute;
              content: "";
              top: calc(50% - 284.5px);
              left: -180px;
              width: 186px;
              height: 569px;
              background: url(../../../../assets/images/zemiverse_2306/content3_img1.png) no-repeat 50% 50%;
              background-size: contain;
            }

            &::before {
              z-index: 30;
              position: absolute;
              content: "";
              top: calc(50% - 284.5px);
              right: -180px;
              width: 268px;
              height: 547px;
              background: url(../../../../assets/images/zemiverse_2306/content3_img2.png) no-repeat 50% 50%;
              background-size: contain;
            }

            > .player {
              position: relative;
              width: 100% !important;
              height: 0 !important;
              padding-top: 58%;
              overflow: hidden;
              border-radius: 30px;
              border: 14px solid $primary-600;

              > video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }

      > .content4 {
        width: 100%;
        padding: 156px 0 221px;
        @include flex(flex, $display-flex-column-no, center, center);

        > .content4__inner {
          width: 1170px;
          @include flex(flex, $display-flex-column-no, center, center);

          > .title {
            color: $neutral-900;
          }

          > .description {
            color: $neutral-600;
            margin: 14px 0 60px;
          }

          > .boxes {
            width: 100%;
            @include flex(flex, $display-flex-row-no, flex-start, space-between);
            gap: 30px;

            > .box {
              position: relative;
              flex: 1;
              height: 346px;
              @include flex(flex, $display-flex-column-no, center, flex-start);
              padding: 45px;
              border-radius: 20px;
              background-color: $white;
              filter: drop-shadow(0px 15px 10px rgba(0, 0, 0, 0.12));

              > .title {
                @include NanumSquare-headline-32UB($font-color: $primary-700, $align: center);
              }

              > .description {
                @include NanumSquare-label-18UB($font-color: $neutral-600, $align: center);
              }

              > .image {
                position: absolute;
                bottom: -60px;
              }

              &:nth-of-type(1) {
                > .image {
                  width: 261px;
                  height: 241px;
                  background: url(../../../../assets/images/zemiverse_2306/content4_img1.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }

              &:nth-of-type(2) {
                > .image {
                  width: 299px;
                  height: 299px;
                  background: url(../../../../assets/images/zemiverse_2306/content4_img2.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }

              &:nth-of-type(3) {
                > .image {
                  width: 255px;
                  height: 255px;
                  background: url(../../../../assets/images/zemiverse_2306/content4_img3.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }
            }
          }
        }
      }

      > .content5 {
        width: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 73.12%, rgba(158, 119, 237, 0.02) 100%), #f4ebff;
        @include flex(flex, $display-flex-column-no, center, center);
        padding: 171px 0 221px;

        > .content5__inner {
          width: 1170px;
          @include flex(flex, $display-flex-column-no, center, center);

          > .top {
            width: 100%;
            @include flex(flex, $display-flex-row-no, flex-end, space-between);
            margin-bottom: 29px;

            > .title {
              text-align: left;
              color: $neutral-900;
            }
          }

          > .bottom {
            width: 100%;
            @include flex(flex, $display-flex-row-no, center, space-between);

            > .box {
              border-radius: 50%;
              width: 370px;
              height: 370px;
              @include flex(flex, $display-flex-column-no, center, center);
              background: $white;
              filter: drop-shadow(0px 15px 10px rgba(0, 0, 0, 0.12));

              > .image {
                width: 195px;
                height: 195px;
              }

              > .title {
                @include NanumSquare-title-18UB($font-color: $primary-700, $align: center);
                margin: 17px 0 4px;
              }

              > .description {
                @include NanumSquare-body-14($font-color: $neutral-600, $align: center);
              }

              &:nth-of-type(1) {
                > .image {
                  background: url(../../../../assets/images/zemiverse_2306/content5_img1.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }

              &:nth-of-type(2) {
                > .image {
                  background: url(../../../../assets/images/zemiverse_2306/content5_img2.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }

              &:nth-of-type(3) {
                > .image {
                  background: url(../../../../assets/images/zemiverse_2306/content5_img3.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }
            }
          }
        }
      }

      > .content6 {
        position: relative;
        width: 100%;
        height: 819px;
        @include flex(flex, $display-flex-column-no, center, center);

        > .content6__inner__bg {
          z-index: 20;
          position: absolute;
          width: 100%;
          height: 100%;

          > div {
            position: absolute;

            &.img1 {
              top: 490px;
              left: 155px;
              width: 38px;
              height: 38px;
              background: url(../../../../assets/images/zemiverse_2306/content6_img1.png) no-repeat 50% 50%;
              background-size: contain;
            }

            &.img2 {
              top: 70px;
              left: 206px;
              width: 190px;
              height: 93px;
              background: url(../../../../assets/images/zemiverse_2306/content6_img2.png) no-repeat 50% 50%;
              background-size: contain;
            }

            &.img3 {
              top: 564px;
              left: 301px;
              width: 90px;
              height: 90px;
              background: url(../../../../assets/images/zemiverse_2306/content6_img3.png) no-repeat 50% 50%;
              background-size: contain;
            }

            &.img4 {
              top: 227px;
              left: 413px;
              width: 61px;
              height: 62px;
              background: url(../../../../assets/images/zemiverse_2306/content6_img4.png) no-repeat 50% 50%;
              background-size: contain;
            }

            &.img5 {
              top: 114px;
              left: 790px;
              width: 105px;
              height: 105px;
              background: url(../../../../assets/images/zemiverse_2306/content6_img5.png) no-repeat 50% 50%;
              background-size: contain;
            }

            &.img6 {
              top: 500px;
              left: 850px;
              width: 69px;
              height: 59px;
              background: url(../../../../assets/images/zemiverse_2306/content6_img6.png) no-repeat 50% 50%;
              background-size: contain;
            }

            &.img7 {
              top: 136px;
              right: 430px;
              width: 104px;
              height: 99px;
              background: url(../../../../assets/images/zemiverse_2306/content6_img7.png) no-repeat 50% 50%;
              background-size: contain;
            }

            &.img8 {
              top: 512px;
              right: 312px;
              width: 140px;
              height: 140px;
              background: url(../../../../assets/images/zemiverse_2306/content6_img8.png) no-repeat 50% 50%;
              background-size: contain;
            }

            &.img9 {
              top: 181px;
              right: 225px;
              width: 140px;
              height: 140px;
              background: url(../../../../assets/images/zemiverse_2306/content6_img9.png) no-repeat 50% 50%;
              background-size: contain;
            }

            &.img10 {
              top: 290px;
              right: 180px;
              width: 75px;
              height: 74px;
              background: url(../../../../assets/images/zemiverse_2306/content6_img10.png) no-repeat 50% 50%;
              background-size: contain;
            }

            &.img11 {
              top: 61px;
              right: 148px;
              width: 38px;
              height: 38px;
              background: url(../../../../assets/images/zemiverse_2306/content6_img11.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
        }

        > .content6__inner {
          z-index: 30;
          position: absolute;
          width: 1170px;
          @include flex(flex, $display-flex-row-no, center, space-between);

          > .left {
            width: 515px;
            height: 507px;
            background: url(../../../../assets/images/zemiverse_2306/content6_mainimg.png) no-repeat 50% 50%;
            background-size: contain;
          }

          > .right {
            @include flex(flex, $display-flex-column-no, flex-start, center);

            > p {
              text-align: left;
            }

            > .title {
              color: $neutral-900;
            }

            > .description {
              margin: 12px 0 24px;
            }
          }
        }
      }

      > .content7 {
        width: 100%;
        background: url(../../../../assets/images/zemiverse_2306/content7_bg.png) no-repeat 50% 50%;
        background-size: cover;
        padding: 369px 0 292px;
        @include flex(flex, $display-flex-column-no, center, center);

        > .content7__inner {
          width: 1170px;
          @include flex(flex, $display-flex-row-no, center, space-between);
          gap: 16.5px;

          > .left {
            max-width: 48%;
            @include flex(flex, $display-flex-column-no, flex-start, center);

            > p {
              width: 100%;
              text-align: left;
              color: $white;
              filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));

              &:nth-of-type(2) {
                margin: 8px 0 30px;
              }

              &:nth-of-type(3) {
                margin-bottom: 30px;
              }
            }
          }

          > .right {
            position: relative;
            width: 50%;
            height: 768px;
            background: url(../../../../assets/images/zemiverse_2306/content7.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
      }

      > .content8 {
        width: 100%;
        padding: 156px;
        @include flex(flex, $display-flex-column-no, center, center);
        > .content8__inner {
          width: 1170px;
          @include flex(flex, $display-flex-row-no, center, space-between);
          gap: 30px;
          > .left {
            min-width: 570px;
            height: 570px;
            background: url(../../../../assets/images/zemiverse_2306/content8_img.png) no-repeat 50% 50%;
            background-size: contain;
          }
          > .right {
            @include flex(flex, $display-flex-column-no, center, center);
            gap: 12px;
            > p {
              width: 100%;
              text-align: left;
              &.title {
                color: $neutral-900;
              }
              &.description {
                color: $neutral-400;
              }
            }
          }
        }
      }

      > .content9 {
        position: relative;
        width: 100%;
        height: 676px;
        @include flex(flex, $display-flex-column-no, center, center);
        background: url(../../../../assets/images/zemiverse_2306/content8_bg.png) no-repeat 50% 50%;
        background-size: cover;
        overflow: hidden;

        > .content9__inner {
          z-index: 30;
          position: relative;
          width: 100%;
          height: 100%;
          padding: 156px;
          @include flex(flex, $display-flex-column-no, center, flex-start);

          > .title {
            color: $white;
            margin-bottom: 14px;
            text-align: center;
          }

          > .box {
            @include flex(flex, $display-flex-row-no, center, center);
            gap: 14px;
          }
        }

        &::after {
          z-index: 25;
          content: "";
          position: absolute;
          bottom: -150px;
          left: -170px;
          width: 791px;
          height: 791px;
          background: url(../../../../assets/images/zemiverse_2306/content9_img1.png) no-repeat 50% 50%;
          background-size: contain;
        }

        &::before {
          z-index: 25;
          content: "";
          position: absolute;
          bottom: -150px;
          right: -180px;
          width: 819px;
          height: 833px;
          background: url(../../../../assets/images/zemiverse_2306/content9_img2.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .container {
    &.zemiverse {
      > .container__inner {
        .title {
          @include NanumSquare-display-45UB($font-color: $primary-700, $align: center);
        }

        .description {
          @include NanumSquare-body-18($font-color: $neutral-700, $align: center);
        }

        > .content1 {
          // height: calc(100vh - 56px);
          height: 600px;

          > .content1__inner {
            > .title,
            > .description {
              @include NanumSquare-display-48UB($font-color: $white);
            }
          }
        }

        > .content2 {
          padding: 60px 0;

          > .content2__inner {
            > .description {
              margin: 16px 0 24px;
            }

            > .swiper {
              width: 100%;
              // margin: 0 32px 40px 32px;

              > .swiper-wrapper {
                > .swiper-slide {
                  // width: 700px;
                  height: 400px;
                  // min-width: 700px;
                  min-height: 400px;
                  border-radius: 16px;

                  &.swiper-slide-active {
                    // width: 700px;
                    height: 400px;
                    // min-width: 700px;
                    min-height: 400px;
                  }
                }
              }
            }
          }
        }

        > .content3 {
          padding: 106px 0 186px;

          > .content3__inner {
            > .title,
            > .description {
              &.description {
                margin: 16px 0 64px;
              }
            }

            > .player__box {
              width: 690px;
              height: 380px;

              &::after,
              &::before {
                display: none;
              }
            }
          }
        }

        > .content4 {
          padding: 81px 24px 161px;

          > .content4__inner {
            width: 100%;
            @include flex(flex, $display-flex-column-no, flex-start, center);

            > .title {
              width: calc(50% - 15px);
              @include NanumSquare-headline-36($font-color: $neutral-900, $align: left);
            }

            > .description {
              width: calc(50% - 15px);
              text-align: left;
              margin: 14px 0 150px;
            }

            > .boxes {
              position: relative;

              > .box {
                &:nth-of-type(1) {
                  position: absolute;
                  top: -428px;
                  right: 0;
                  width: calc(50% - 15px);
                }
              }
            }
          }
        }

        > .content5 {
          padding: 171px 24px 91px;

          > .content5__inner {
            width: 100%;

            > .top {
              @include flex(flex, $display-flex-column-no, flex-start, center);
              gap: 14px;

              > .title {
                @include NanumSquare-headline-36($font-color: $neutral-900, $align: left);
              }

              > .normal_button {
                margin-bottom: 118px;
              }
            }

            > .bottom {
              position: relative;

              > .box {
                &:nth-of-type(1) {
                  position: absolute;
                  top: -400px;
                  right: 0;
                }
              }
            }
          }
        }

        > .content6 {
          height: 1050px;
          padding: 0;

          > .content6__inner__bg {
            > div {
              &.img2 {
                top: 40px;
                left: 60px;
              }

              &.img3 {
                top: 450px;
                left: inherit;
                right: 140px;
              }

              &.img4 {
                top: 200px;
                left: 38px;
              }

              &.img5 {
                top: 50px;
                left: inherit;
                right: 80px;
              }

              &.img7 {
                top: 400px;
                right: 0;
              }

              &.img8 {
                top: 512px;
                right: inherit;
                left: 24px;
                width: 140px;
                height: 140px;
                background: url(../../../../assets/images/zemiverse_2306/content6_img8.png) no-repeat 50% 50%;
                background-size: contain;
              }

              &.img6,
              &.img9,
              &.img10,
              &.img11 {
                display: none;
              }
            }
          }

          > .content6__inner {
            width: 100%;
            @include flex(flex, $display-flex-column-no, center, space-between);

            > .right {
              @include flex(flex, $display-flex-column-no, center, center);

              > p {
                text-align: center;
              }

              > .title {
                @include NanumSquare-headline-36($font-color: $neutral-900, $align: center);
              }
            }
          }
        }

        > .content7 {
          padding: 101px 0 100px;

          > .content7__inner {
            width: 100%;
            @include flex(flex, $display-flex-column-no, center, space-between);

            > .left {
              @include flex(flex, $display-flex-column-no, center, center);

              > p {
                text-align: center;
              }
            }

            > .right {
              min-width: 620px;
            }
          }
        }

        > .content8 {
          padding: 91px 0;
          > .content8__inner {
            width: 100%;
            @include flex(flex, $display-flex-column-no, center, center);
            > .right {
              width: 100%;
              > p {
                text-align: center;
              }
            }
          }
        }

        > .content9 {
          // padding: 60px 24px;

          > .content9__inner {
            width: 100%;
            padding: 140px;
            // @include flex(flex, $display-flex-column-no, center, center);
          }
          &::after {
            left: -200px;
          }
          &::before {
            right: -250px;
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .container {
    &.zemiverse {
      > .container__inner {
        .title {
          @include NanumSquare-headline-28UB($font-color: $primary-700, $align: center);
        }

        .description {
          @include NanumSquare-body-14($font-color: $neutral-700, $align: center);
        }

        > .content1 {
          > .content1__inner {
            > .title,
            > .description {
              @include NanumSquare-display-36($font-color: $white, $align: center);
            }
          }
        }

        > .content2 {
          > .content2__inner {
            > .description {
            }

            > .swiper {
              margin: 0 0 40px 0;

              > .swiper-wrapper {
                > .swiper-slide {
                  // width: 300px;
                  height: 171px;
                  // min-width: 300px;
                  min-height: 171px;
                  // border-radius: 16px;

                  &.swiper-slide-active {
                    // width: 320px;
                    height: 182px;
                    // min-width: 320px;
                    min-height: 182px;
                  }
                }
              }
            }
          }
        }

        > .content3 {
          padding: 60px 0;

          > .content3__inner {
            > .title,
            > .description {
              &.description {
                margin: 16px 0;
              }
            }

            > .player__box {
              width: 320px;
              height: 176px;
              > .player {
                border: none;
              }
            }
          }
        }

        > .content4 {
          padding: 81px 24px 160px;

          > .content4__inner {
            > .title {
              width: 100%;
            }

            > .description {
              width: 100%;
              margin: 14px 0 30px;
            }

            > .boxes {
              @include flex(flex, $display-flex-column-no, center, center);
              gap: 120px;

              > .box {
                width: 100%;
                min-height: 346px;

                &:nth-of-type(1) {
                  position: inherit;
                  top: inherit;
                  right: inherit;
                  width: 100%;
                }
              }
            }
          }
        }

        > .content5 {
          padding: 91px 20px 91px;

          > .content5__inner {
            > .top {
              @include flex(flex, $display-flex-column-no, center, center);

              > .title {
                width: 100%;
                @include NanumSquare-headline-28UB($font-color: $neutral-900, $align: center);
              }

              > .normal_button {
                margin-bottom: 30px;
              }
            }

            > .bottom {
              @include flex(flex, $display-flex-column-no, center, center);
              gap: 30px;

              > .box {
                width: 100%;
                // max-width: 320px;
                max-height: 320px;
                border-radius: 20px;
                > .image {
                  width: 180px;
                  height: 180px;
                }
                &:nth-of-type(1) {
                  position: inherit;
                  top: inherit;
                  right: inherit;
                }
              }
            }
          }
        }

        > .content6 {
          height: 728px;

          > .content6__inner__bg {
            > div {
              &.img1 {
                width: 20px;
                height: 20px;
                top: 332px;
                left: 29px;
              }

              &.img2 {
                width: 80px;
                height: 70px;
                top: 32px;
                left: 65px;
              }

              &.img3 {
                width: 50px;
                height: 50px;
                top: 386px;
                left: inherit;
                right: 72px;
              }

              &.img4 {
                width: 30px;
                height: 30px;
                top: 117px;
                left: 15px;
              }

              &.img5 {
                width: 80px;
                height: 80px;
                top: 83px;
                left: inherit;
                right: 27px;
              }

              &.img7 {
                width: 44px;
                height: 42px;
                top: 321px;
                right: 5px;
              }

              &.img8 {
                top: 374px;
                right: inherit;
                left: -14px;
                width: 57px;
                height: 51px;
              }
            }
          }

          > .content6__inner {
            > .left {
              width: 100%;
              height: 354px;
              margin-bottom: 15px;
            }

            > .right {
              > .title {
                @include NanumSquare-headline-28UB($font-color: $neutral-900, $align: center);
              }
            }
          }
        }

        > .content7 {
          padding: 87px 15px 86px;

          > .content7__inner {
            > .left {
              width: 100%;
              max-width: inherit;

              > p {
                width: 100%;
                &:nth-of-type(2) {
                  margin: 4px 0 16px;
                }
              }
            }

            > .right {
              min-width: inherit !important;
              width: 100%;
              height: 343px;
              background: url(../../../../assets/images/zemiverse_2306/content7_mobile.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
        }

        > .content8 {
          padding: 61px 15px;
          > .content8__inner {
            width: 100%;
            .left {
              min-width: 320px;
              height: 320px;
            }
            > .right {
              width: 100%;
              > .description {
                word-break: inherit;
              }
            }
          }
        }

        > .content9 {
          height: 400px;

          > .content9__inner {
            padding: 102px 0 0 0;
            > .title {
              text-align: center;
            }
            > .box {
              @include flex(flex, $display-flex-column-no, center, center);
              > .normal_button {
                width: 205px;
              }
            }
          }
          &::after,
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(25px);
  }
}

@keyframes scrolldown {
  0% {
    top: -13px;
  }

  100% {
    top: calc(100% + 13px);
  }
}
